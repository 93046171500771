import { Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import { IAppTranslationService, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleApiResponseStatus, PurpleLoaderService } from 'purple-lib';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/default/environment';
import * as api from '../sdk/api/api/localization.service';
import { StorageService } from '../storage/storage.service';
import { GetAllTranslationValuesResponse, Language } from '../sdk/api';

@Injectable({
  providedIn: 'root'
})
export class AppTranslationService implements IAppTranslationService {
  basePath: string = localStorage.getItem("BasePath")!;
  availableLanguages: Language[] = [];
  translations: Map<string, Map<string, string>> | undefined | null;
  currentLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem("DefaultLanguage")??"none");
  isInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  editTranAffix: string = "";
  newTranSymbol: string = "";

  translationLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem("DefaultLanguage")??"none");

  newTranslations: Map<string, string> = new Map<string, string>();

  private readonly language = "Language";

  constructor(private storageSvc: StorageService, private localizationSvc: api.LocalizationService,
    private router: Router, private loaderSvc: PurpleLoaderService, private apiProxySvc: PurpleApiProxyService) {
    this.editTranAffix = environment.EDIT_TRAN_AFFIX;
    this.newTranSymbol = environment.NEW_TRAN_SYMBOL;
  }

  changeTranslationLanguage(languageId: string): void {
    this.translationLanguage.next(languageId);
  }


  async getLanguages() {
    const res = await lastValueFrom(this.localizationSvc.getLanguages(this.currentLanguage.getValue()))
    if (res.status == PurpleApiResponseStatus.Success) {
      this.availableLanguages = res.data ?? [];
    }
  }

  async checkIfCultureExist(cultureId: string, url: string): Promise<boolean | UrlTree> {

    await this.getLanguages()

    const resIdx = this.availableLanguages.findIndex(f => f.languageId == cultureId);
    if (resIdx != -1) {
      this.storageSvc.set(this.language, cultureId);
      this.currentLanguage.next(cultureId);

      return true
    } else {
      let defCulture = localStorage.getItem("DefaultLanguage")??"none";

      const resIdx = this.availableLanguages.findIndex(f => f.languageId.strEq(defCulture ?? ''));
      if (resIdx == -1) {
        defCulture = this.availableLanguages[0].languageId
      }

      this.storageSvc.set(this.language, defCulture);
      this.currentLanguage.next(defCulture!);
      const newUrl = url.replace(cultureId, defCulture!);

      return this.router.parseUrl(newUrl);
    }

  }


  async initialize(forceRefresh: boolean = false): Promise<void> {
    if (!forceRefresh && this.isInitialized.value) {
      return;
    }

    this.loaderSvc.addRequest("global");
    const lang = this.currentLanguage.value;
    const currentCrc = this.storageSvc.get<string>("TranslationCrc") ?? undefined;

    await this.apiProxySvc.makeRequestErrorPage<boolean>(() => this.localizationSvc.checkLocalizationUpdates(lang, {
      checksum: currentCrc
    }), true, "internal-loader", 0, "internal-error", () => {
      this.localizationSvc.checkLocalizationUpdates(lang, {
        checksum: currentCrc
      })
    }, async (check: PurpleApiMakeRequestResponse<boolean>) => {
      if (!check.data) {

        await this.apiProxySvc.makeRequestErrorPage<GetAllTranslationValuesResponse>(() => this.localizationSvc.getAllTranslationValues(lang),
          true, "internal-loader", 0, "internal-error", () => { this.localizationSvc.getAllTranslationValues(lang) }, (res: PurpleApiMakeRequestResponse<GetAllTranslationValuesResponse>) => {
            //Update crc
            this.storageSvc.set("TranslationCrc", res.data!.checksum)

            //Update translations
            this.translations = (res.data!.languages! as any).reduce(function (map: any, obj: any) {
              map[obj.languageId!] = obj.translations.reduce((m: any, o: any) => {
                m[o.key!] = o.value
                return m
              });
              return map;
            }, {});

            this.storageSvc.set("Translations", this.translations)
          });
      } else {
        this.translations = this.storageSvc.get("Translations");
      }
      this.loaderSvc.removeRequest("global");
      this.isInitialized.next(true);
    })
  }

  async switchLanguage(lang: string) {
    const queryParams: Params = this.router.parseUrl(this.router.url).queryParams;

    var newUrl = this.router.url.replace(this.currentLanguage.value, lang);
    if (newUrl.includes("?")) {
      newUrl = newUrl.split("?")[0]
    }

    this.storageSvc.set(this.language, lang);
    this.currentLanguage.next(lang);
    await this.initialize(true);

    await this.router.navigate([newUrl], {
      replaceUrl: true,
      queryParams: queryParams
    });

    window.location.reload()
  }

  async addOrUpdateTranslation(key: string, value: string): Promise<string | undefined> {

    var res = await lastValueFrom(this.localizationSvc.addOrUpdateTranslationValue(this.currentLanguage.value, {
      key: key,
      value: value
    }));

    //TODO cambiare quando passi alle nuove api
    return res.data ? "" : undefined;
  }

  async checkTranslationUpdate(checksum: string): Promise<boolean> {

    var res = await lastValueFrom(this.localizationSvc.checkLocalizationUpdates(this.currentLanguage.value, {
      checksum: checksum
    }));

    return res.data ?? false;
  }


  async getTranslationsChecksum(): Promise<string> {

    var res = await lastValueFrom(this.localizationSvc.getChecksum(this.currentLanguage.value));

    return res.data ?? "";
  }
}
