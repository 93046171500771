import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';
import { ReferralService } from 'src/app/referral/referral.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, GmtInfo, PRPVPolicyFull, PurpleApiResponseStatus, SaleEventBookedSlot, SaleEventDetail, SaleEventDetailSlot, SaleEventDetailSlotItem, UserExtendedReferral } from 'src/core-modules/sdk/api';
import { SwiperComponent } from 'swiper/angular';
import { BookSlotDrawerComponent } from '../drawers/book-slot-drawer/book-slot-drawer.component';
import { BookSlotModalComponent } from '../modals/book-slot-modal/book-slot-modal.component';
import { PrivateInviteFriendsDrawerComponent } from '../drawers/private-invite-friends-drawer/private-invite-friends-drawer.component';
import { InviteFriendsDrawerComponent } from '../drawers/invite-friends-drawer/invite-friends-drawer.component';

@Component({
  selector: 'app-private-sale-event-detail',
  templateUrl: './private-sale-event-detail.component.html',
  styleUrls: ['./private-sale-event-detail.component.scss']
})
export class PrivateSaleEventDetailComponent implements OnInit, AfterViewInit {

  constructor(public tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private el: ElementRef, private refSvc: ReferralService,
    private bookSvc: BookingService, private accRouter: ActivatedRoute, public modalSvc: NzModalService, private drawerSvc: NzDrawerService, private router: Router) {

  }
  slidesForView: number = 3;
  tooShort: boolean = false;
  saleEvent: number = 3;
  saleEventDetail: SaleEventDetail | undefined;
  isSwiperInit: boolean = false;

  @ViewChild('bookingCalendarDaysContainer') daysContainer: ElementRef | undefined;
  @ViewChild('daySlider', { static: false }) swiper?: SwiperComponent;

  invitedFriends: Array<UserExtendedReferral> = []

  async ngOnInit() {
    this.isSwiperInit = false;
    //console.log("SNAP: ", this.accRouter.children[0].snapshot)
    var sec: string | undefined = this.accRouter.children[0].snapshot.params['saleEventCode'];

    this.route.queryParams
      .subscribe(params => {
        this.seSvc.getSaleEventParameters(params);
      });

    if (sec != undefined) {
      await this.refSvc.reedemReferralCode();
      await this.getSaleEventDetail(sec)

      setTimeout(() => {
        const conWi = (this.daysContainer?.nativeElement as HTMLElement)?.clientWidth??undefined;

        if (conWi != undefined) {
          this.slidesForView = Math.floor(conWi / 120);
          this.tooShort = (this.saleEventDetail?.daySlots.length ?? 0) <= this.slidesForView;


          //console.log("DaysContainer: ", conWi, " - ", this.slidesForView, " - ", this.saleEventDetail?.daySlots.length);
          //console.log("TooShort: ", this.tooShort);

          if (this.tooShort) {
            this.slidesForView = this.saleEventDetail?.daySlots.length ?? 0;
          }
        }
      }, 200);
    }


  }

  showInviteFriendsDrawer() {
    if (this.saleEventDetail!.saleEvent.isPrivateInviteCounter) {
      this.drawerSvc.create<PrivateInviteFriendsDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: PrivateInviteFriendsDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "private-invite-friends",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          saleEventId: this.saleEventDetail?.saleEvent.saleEventId
        }
      });
    } else {
      if ((this.saleEventDetail!.userSlotBooked??undefined) != undefined) {
        this.drawerSvc.create<InviteFriendsDrawerComponent, { value: string }, string>({
          nzTitle: undefined,
          nzContent: InviteFriendsDrawerComponent,
          nzClosable: false,
          nzWrapClassName: "invite-friends",
          nzFooter: undefined,
          nzPlacement: 'bottom',
          nzContentParams: {
            book: {
              address: this.saleEventDetail!.saleEvent.addressFull!,
              addressExternalId: this.saleEventDetail!.saleEvent.addressExternalId!,
              addressLat: this.saleEventDetail!.saleEvent.addressLat!,
              addressLon: this.saleEventDetail!.saleEvent.addressLon!,
              isPrivateInviteCounter: this.saleEventDetail!.saleEvent.isPrivateInviteCounter,
              saleEventCode: this.saleEventDetail!.saleEvent.saleEventCode,
              saleEventEmail: this.saleEventDetail!.saleEvent.saleEventEmail,
              saleEventId: this.saleEventDetail!.saleEvent.saleEventId,
              saleEventName: this.saleEventDetail!.saleEvent.saleEventName,
              saleEventPhone: this.saleEventDetail!.saleEvent.saleEventPhone,
              userCanInvite: this.saleEventDetail!.canInvite,
              userCanDelete: true, //TODO
              slot: {
                bookingId: this.saleEventDetail!.userSlotBooked!.saleEventSlotBookingId,
                requireAssistance: false,
                roleId: this.saleEventDetail!.userSlotBooked!.roleId,
                roleName: this.saleEventDetail!.userSlotBooked!.roleName,
                slotEndDate: this.saleEventDetail!.userSlotBooked!.slotEndDate,
                slotStartDate: this.saleEventDetail!.userSlotBooked!.slotStartDate,
                slotGmtInfo: this.saleEventDetail!.userSlotBooked!.slotGmtInfo,
              }
            }
          }
        });
      }
    }
  }

  swiperInit() {
    this.isSwiperInit = true;
  }

  async getSaleEventDetail(saleEventCode: string) {
    const resp = await lastValueFrom(this.bookSvc.getSaleEventDetail(this.tsvc.currentLanguage.value, {
      saleEventCode: saleEventCode
    }));

    if (resp.status == PurpleApiResponseStatus.Success) {
      this.saleEventDetail = resp.data!

      //this.seSvc.currentSaleEventUtc.next(resp.data?.gmtInfo);
      this.seSvc.currentSaleEvent.next(resp.data?.saleEvent);


      if (resp.data!.bookingClosed) {
        const refs = await lastValueFrom(
          this.bookSvc.getUserFreeSaleEventReferral(this.tsvc.currentLanguage.value, {
            saleEventId: resp.data!.saleEvent.saleEventId
          }));

        this.invitedFriends = [...refs.data?.redeemReferralFriends ?? [], ...refs.data?.pendingReferralUsers ?? []].sort((a, b) => a.firstName.localeCompare(b.firstName))
      }
    } else {
      this.saleEventDetail = undefined
      await this.router.navigate(['404']);
    }
  }

  ngAfterViewInit(): void {
    this.scrollToCurrentElement(200);
    /* if (this.saleEventDetail != undefined) {
      this.swiper!.swiperRef.slideTo(this.saleEventDetail.currentSlide);
      this.swiper!.swiperRef.activeIndex = this.saleEventDetail.currentSlide;
      this.swiper!.swiperRef.updateSlidesClasses();
    } */
  }


  scrollToCurrentElement(delay: number) {
    const currentElements = document.getElementsByClassName('booked-mobile');
    setTimeout(() => {
      if (currentElements.length > 0) {
        currentElements[0].scrollIntoView({ behavior: 'smooth', block: "center" });
      }
    }, delay);
  }

  panelActive(slot: SaleEventDetailSlot): boolean {
    return slot.slots.findIndex(m => m.bookedByUser) != -1;
  }

  slideNext() {
    //this.swiper!.swiperRef.slideNext(100);
    if ((this.swiper!.swiperRef.activeIndex) < (this.saleEventDetail!.daySlots.length - 1)) {
      const newId = this.swiper!.swiperRef.activeIndex + 1;
      this.swiper!.swiperRef.slideTo(newId);
      this.swiper!.swiperRef.activeIndex = newId;
      this.swiper!.swiperRef.updateSlidesClasses();
    }
  }
  slidePrev() {
    //this.swiper!.swiperRef.slidePrev(100);
    if ((this.swiper?.swiperRef.activeIndex ?? 0) > 0) {
      const newId = this.swiper!.swiperRef.activeIndex - 1;
      this.swiper!.swiperRef.slideTo(newId);
      this.swiper!.swiperRef.activeIndex = newId;
      this.swiper!.swiperRef.updateSlidesClasses();
    }
  }

  scrollTo = () => {
    //el.scrollIntoView({ behavior: 'smooth' });
    (this.daysContainer!.nativeElement as HTMLElement).scrollIntoView({ behavior: 'smooth' })

  }

  slideClick(_swiper: any, event: any) {
    const path = event.path || (event.composedPath && event.composedPath());

    if (path != undefined || path != null) {
      var clickedElementSlide = path.find((f: any) => f != undefined && f.classList != undefined && f.classList.contains('swiper-slide'));

      if (clickedElementSlide != undefined || clickedElementSlide != null) {
        const idx = (clickedElementSlide as HTMLElement).getAttribute('data-swiper-slide-index');
        const def = idx == null ? this.swiper!.swiperRef.activeIndex : +idx;
        //console.log("Indice: ", def);
        //this.swiper!.swiperRef.slideTo(def, 100);
        this.swiper!.swiperRef.activeIndex = def;
        this.swiper!.swiperRef.updateSlidesClasses();
        //console.log(this.swiper!.swiperRef.activeIndex)
      }

    }

  }

  onSlideChange() {
    this.saleEventDetail?.daySlots[this.swiper!.swiperRef.activeIndex] ?? []
  }

  checkIfSlotIsFull(slot: SaleEventDetailSlotItem): boolean {
    return (!slot.isMixedRoleEnabled && slot.slotAvailableCapacity == 0) ||
      (slot.isMixedRoleEnabled && slot.maxMixedRoleAttendees != -1 && slot.maxMixedRoleAttendees == slot.currentSlotRoleAttendees);
  }

  showBookingModal(slot: SaleEventDetailSlotItem, dayGmt: GmtInfo) {
    //console.log("SHOW BOOKING MODAL")    
    if (!slot.bookedByUser &&
      ((!slot.isMixedRoleEnabled && (slot.slotAvailableCapacity == -1 || slot.slotAvailableCapacity > 0)) ||
        (slot.isMixedRoleEnabled && (slot.maxMixedRoleAttendees == -1 || slot.maxMixedRoleAttendees > slot.currentSlotRoleAttendees)))) {
      this.seSvc.currentSaleEventUtc.next(dayGmt);
      const idx = this.swiper?.swiperRef.activeIndex;
      //console.log("SHOW BOOKING MODAL BEFORE CREATE")


      this.modalSvc.create<BookSlotModalComponent, { slot: SaleEventDetailSlotItem | undefined, policies: PRPVPolicyFull[], bookedSlot: SaleEventBookedSlot | undefined, saleEventName: string | undefined, canInvite?: boolean }>({
        nzContent: BookSlotModalComponent,
        nzTitle: undefined,
        nzWidth: '1000px',
        nzCloseIcon: undefined,
        nzClosable: false,
        nzCentered: true,
        nzClassName: "book-slot-modal",
        nzData: {
          slot: slot,
          bookedSlot: this.saleEventDetail?.userSlotBooked,
          saleEventName: this.saleEventDetail?.saleEvent.saleEventName,
          policies: this.saleEventDetail?.policies??[],
          canInvite: this.saleEventDetail?.canInvite
        },
        nzFooter: null,
        nzMaskClosable: false,
      }).afterClose.subscribe(async () => {

        //this.saleEventDetail = undefined;
        await this.getSaleEventDetail(this.seSvc.currentSaleEvent.value?.saleEventCode!);
        this.swiper!.swiperRef.activeIndex = idx ?? 0;
        this.swiper!.swiperRef.slideTo(idx ?? 0);
        this.swiper!.swiperRef.updateSlidesClasses();
      });
    }

  }

  showBookingDrawer(slot: SaleEventDetailSlotItem, dayGmt: GmtInfo) {
    if (!slot.bookedByUser &&
      ((!slot.isMixedRoleEnabled && (slot.slotAvailableCapacity == -1 || slot.slotAvailableCapacity > 0)) ||
        (slot.isMixedRoleEnabled && (slot.maxMixedRoleAttendees == -1 || slot.maxMixedRoleAttendees > slot.currentSlotRoleAttendees)))) {
      this.seSvc.currentSaleEventUtc.next(dayGmt);
      this.drawerSvc.create<BookSlotDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: BookSlotDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "book-slot",
        nzContentParams: {
          slot: slot,
          bookedSlot: this.saleEventDetail?.userSlotBooked,
          policies: this.saleEventDetail?.policies
        },
        nzFooter: undefined,
        nzPlacement: 'bottom'
      }).afterClose.subscribe(async () => {
        this.scrollToCurrentElement(5)
        await this.getSaleEventDetail(this.seSvc.currentSaleEvent.value?.saleEventCode!)
      });
    }

  }

  checkCanInvite(): boolean{
    if(this.saleEventDetail!.saleEvent.isPrivateInviteCounter){
      return this.saleEventDetail!.canInvite;
    }else{
      return ((this.saleEventDetail!.userSlotBooked??undefined) != undefined) && this.saleEventDetail!.canInvite;
    }
  }
}
