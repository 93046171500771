import { Component, Input, OnInit } from '@angular/core';
import { SaleEventDetail, UserNextSaleEventBookingItem } from 'src/core-modules/sdk/api';
import { PrivateInviteFriendsModalComponent } from '../../modals/private-invite-friends-modal/private-invite-friends-modal.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { InviteFriendsModalComponent } from '../../modals/invite-friends-modal/invite-friends-modal.component';

@Component({
  selector: 'app-base-sale-event-detail-banner',
  templateUrl: './base-sale-event-detail-banner.component.html',
  styleUrls: ['./base-sale-event-detail-banner.component.scss']
})
export class BaseSaleEventDetailBannerComponent implements OnInit {

  constructor(private modalSvc: NzModalService) { }
  @Input() saleEventDetail!: SaleEventDetail;
  @Input() scrollFn!: () => void;

  ngOnInit() {
  }

  checkCanInvite(): boolean{
    if(this.saleEventDetail!.saleEvent.isPrivateInviteCounter){
      return this.saleEventDetail!.canInvite;
    }else{
      return ((this.saleEventDetail!.userSlotBooked??undefined) != undefined) && this.saleEventDetail!.canInvite;
    }
  }
  
  showInviteFriendsModal() {
    if (this.saleEventDetail.saleEvent.isPrivateInviteCounter) {
      this.modalSvc.create<PrivateInviteFriendsModalComponent, { saleEventId: string, saleEventName: string, saleEventPhone: string, saleEventEmail: string }>({
        nzContent: PrivateInviteFriendsModalComponent,
        nzTitle: undefined,
        nzWidth: '1000px',
        nzCloseIcon: undefined,
        nzClosable: false,
        nzCentered: true,
        nzClassName: "private-invite-friends-modal",
        nzData: {
          saleEventId: this.saleEventDetail.saleEvent.saleEventId,
          saleEventEmail: this.saleEventDetail.saleEvent.saleEventEmail,
          saleEventPhone: this.saleEventDetail.saleEvent.saleEventPhone,
          saleEventName: this.saleEventDetail.saleEvent.saleEventName
        },
        nzFooter: null,
        nzMaskClosable: false,
      });
    } else {
      if (this.saleEventDetail.userSlotBooked != undefined) {
        this.modalSvc.create<InviteFriendsModalComponent, UserNextSaleEventBookingItem>({
          nzContent: InviteFriendsModalComponent,
          nzTitle: undefined,
          nzWidth: '1000px',
          nzCloseIcon: undefined,
          nzClosable: false,
          nzCentered: true,
          nzClassName: "invite-friends-modal",
          nzData: {
              address: this.saleEventDetail.saleEvent.addressFull!,
              addressExternalId: this.saleEventDetail.saleEvent.addressExternalId!,
              addressLat: this.saleEventDetail.saleEvent.addressLat!,
              addressLon: this.saleEventDetail.saleEvent.addressLon!,
              isPrivateInviteCounter: this.saleEventDetail.saleEvent.isPrivateInviteCounter,
              saleEventCode: this.saleEventDetail.saleEvent.saleEventCode,
              saleEventEmail: this.saleEventDetail.saleEvent.saleEventEmail,
              saleEventId: this.saleEventDetail.saleEvent.saleEventId,
              saleEventName: this.saleEventDetail.saleEvent.saleEventName,
              saleEventPhone: this.saleEventDetail.saleEvent.saleEventPhone,
              userCanInvite: this.saleEventDetail.canInvite,
              userCanDelete: true, //TODO
              slot: {
                bookingId: this.saleEventDetail.userSlotBooked!.saleEventSlotBookingId,
                requireAssistance: false,
                roleId: this.saleEventDetail.userSlotBooked!.roleId,
                roleName: this.saleEventDetail.userSlotBooked!.roleName,
                slotEndDate: this.saleEventDetail.userSlotBooked!.slotEndDate,
                slotStartDate: this.saleEventDetail.userSlotBooked!.slotStartDate,
                slotGmtInfo: this.saleEventDetail.userSlotBooked!.slotGmtInfo,
              }
          },
          nzFooter: null,
          nzMaskClosable: false,
        });
      }

    }
  }
}
