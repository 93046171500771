<div class="auth-form-container ps-d-flex ps-flex-wrap ps-align-items-start">
  <div class="ps-w-100 ps-d-flex ps-flex-wrap">

    <div class="title-container ps-d-flex ps-w-100 ps-flex-wrap">
      <h1 purpleEditableText tranKey="contact_title" class="auth-form-title ps-mb-0 ps-w-100 ps-d-none ps-d-lg-block purple-title">{{
        'contact_title' | translation: 'Entra in contatto': []}}</h1>
      <h2 purpleEditableText tranKey='contact_subtitle' [innerHtml]="subtitleText | safeHtml"
        class="auth-form-subtitle ps-w-100 ps-d-none ps-d-lg-block ps-mb-0 purple-subtitle"></h2>
      <h2 purpleEditableText tranKey="contact_title_mobile" class="auth-form-title ps-mb-0 ps-w-100 ps-d-block ps-d-lg-none purple-title">
        {{ 'contact_title_mobile' | translation: 'Entra in contatto con noi': []}}</h2>
    </div>

    <div class="information-box-container ps-d-flex ps-flex-wrap ps-w-100">
      <div class="ps-d-lg-flex ps-d-none ps-flex-wrap ps-w-100">

        <div
          class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-7 ps-align-items-center ps-justify-content-end">
          <div class="auth-form-left-container-box ps-d-flex ps-w-100 ps-flex-wrap">
            <div class="ps-w-100 ps-d-flex ps-align-items-start">
              <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
                <nz-form-item>
                  <nz-form-control purpleEditableText tranKey='contact_input_error_required' [nzErrorTip]="'contact_input_error_required' | translation: 'Campo obbligatorio': []">
                    <nz-input-group nzPrefixIcon="shopping">
                      <nz-select formControlName="saleEvent" class="sale-event-select ps-w-100" [ngModel]="guidEmpty">
                          <nz-option *ngFor="let se of selectSaleEvents" [nzLabel]="se.saleEventName"
                            [nzValue]="se.saleEventId">
                          </nz-option>
                        </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-form-control purpleEditableText tranKey='contact_input_error_lastname'
                    [nzErrorTip]="'contact_input_error_lastname' | translation: 'Nome completo obbligatorio!': []">
                    <nz-input-group nzPrefixIcon="user">
                      <input purpleEditableText tranKey='contact_input_fullname' type="text" nz-input formControlName="fullName" 
                        [placeholder]="'contact_input_fullname' | translation: 'Nome Completo': []" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-form-control purpleEditableText tranKey='contact_input_error_email' [nzErrorTip]="'contact_input_error_email' | translation: 'Email obbligatoria!': []">
                    <nz-input-group nzPrefixIcon="mail">
                      <input purpleEditableText tranKey='contact_input_email' type="email" nz-input formControlName="email" 
                      [placeholder]="'contact_input_email' | translation: 'Email': []" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="!isKering">
                  <nz-form-control purpleEditableText tranKey='contact_input_error_phone' [nzValidateStatus]="validateForm.controls['phoneNumber']"
                    [nzErrorTip]="'contact_input_error_phone' | translation: 'Numero di telefono obbligatorio!': []">
                    <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate" nzPrefixIcon="phone">
                      <ng-template #addOnBeforeTemplate>
                        <nz-select formControlName="phoneNumberPrefix" class="phone-select">
                          <nz-option *ngFor="let pref of phonePrefixes" [nzLabel]="pref" [nzValue]="pref"></nz-option>
                        </nz-select>
                      </ng-template>
                      <input formControlName="phoneNumber" purpleEditableText tranKey='contact_input_phone'
                        id="'phoneNumber'" [placeholder]="'contact_input_phone' | translation: 'Telefono': []"
                        nz-input />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-form-control purpleEditableText tranKey='contact_input_error_message'
                    [nzErrorTip]="'contact_input_error_message' | translation: 'Messaggio obbliogatorio!': []">
                    <nz-input-group>
                      <textarea purpleEditableText tranKey='contact_input_message' type="text" nz-input  formControlName="message"
                        [placeholder]="'contact_input_message' | translation: 'Messaggio': []"></textarea>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <button purpleEditableText tranKey='contact_send_message' nz-button class="submit-button purple-button ps-mt-1"
                  nzType="primary">{{ 'contact_send_message' |
                  translation:
                  'Invia messaggio': []}}</button>
              </form>
            </div>
          </div>
        </div>


        <div
          class="auth-form-right-container ps-col-lg-5 ps-col-12 ps-d-flex ps-justify-content-center ps-align-items-start ps-justify-content-start">
          <div class="ps-w-100 ps-d-flex ps-flex-wrap auth-form-right-container-box">
            <h1 purpleEditableText tranKey='contact_all_contact' class="ps-w-100 auth-form-right-container-title">
              {{ 'contact_all_contact' | translation: 'I nostri contatti': []}}
            </h1>
            <div *ngFor="let se of selectSaleEvents" class="ps-w-100  auth-form-right-container-contact-item">
              <h2 *ngIf="showAllSaleEventContacts" class="auth-form-right-container-contact-item-title">{{se.saleEventName | titlecase}}</h2>
              <div class="ps-w-100 ps-d-flex">
                <div class="ps-col-6 ps-p-0">
                  <h3 purpleEditableText tranKey='contact_email' class="ps-w-100 auth-form-right-container-contact-item-field-title">
                    {{ 'contact_email' | translation: 'Email': []}}
                  </h3>
                  <a href="mailto:{{se.saleEventEmail}}" class="auth-form-right-container-contact-item-field-value">
                    {{se.saleEventEmail}}
                  </a>
                </div>
                <div class="ps-col-6 ps-p-0">
                  <h3 purpleEditableText tranKey='contact_phone' class="ps-w-100 auth-form-right-container-contact-item-field-title">
                    {{ 'contact_phone' | translation: 'Phone': []}}
                  </h3>
                  <a href="tel:{{se.saleEventPhone}}" class="auth-form-right-container-contact-item-field-value">
                    {{se.saleEventPhone}}
                  </a>
                </div>
              </div>
              <div class="ps-col-12 ps-mt-3 ps-p-0">
                <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                  <h3 purpleEditableText tranKey='contact_address' class="ps-w-100 auth-form-right-container-contact-item-field-title">
                    {{ 'contact_address' | translation: 'Indirizzo': []}}
                  </h3>
                  <a href="geo:{{se.addressLat}},{{se.addressLon}}" class="auth-form-right-container-contact-item-field-value">
                    {{se.addressFull}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <!-- Mobile -->
      <div class="ps-d-flex ps-d-lg-none ps-flex-wrap ps-w-100 ">
        <nz-tabset>
          <nz-tab [nzTitle]="'contacts_write_us' | translation: 'Contattaci': []">
            <div
            class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-7 ps-align-items-center ps-justify-content-end">
            <div class="auth-form-left-container-box ps-d-flex ps-w-100 ps-flex-wrap">
              <div class="ps-w-100 ps-d-flex ps-align-items-start">
                <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
                  <nz-form-item>
                    <nz-form-control purpleEditableText tranKey='contact_input_error_required' [nzErrorTip]="'contact_input_error_required' | translation: 'Campo obbligatorio': []">
                      <nz-input-group nzPrefixIcon="shopping">
                        <nz-select formControlName="saleEvent"
                          class="sale-event-select ps-w-100" [ngModel]="guidEmpty">
                          <nz-option *ngFor="let se of selectSaleEvents" [nzLabel]="se.saleEventName"
                            [nzValue]="se.saleEventId">
                          </nz-option>
                        </nz-select>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item>
                    <nz-form-control purpleEditableText tranKey='contact_input_error_lastname' purpleEditableText tranKey='contact_input_error_lastname'
                      [nzErrorTip]="'contact_input_error_lastname' | translation: 'Nome completo obbligatorio!': []">
                      <nz-input-group nzPrefixIcon="user">
                        <input type="text" nz-input formControlName="fullName" purpleEditableText tranKey='contact_input_fullname'
                          [placeholder]="'contact_input_fullname' | translation: 'Nome Completo': []" />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item>
                    <nz-form-control purpleEditableText tranKey='contact_input_error_email' [nzErrorTip]="'contact_input_error_email' | translation: 'Email obbligatoria!': []">
                      <nz-input-group nzPrefixIcon="mail">
                        <input purpleEditableText tranKey='contact_input_email' type="email" nz-input formControlName="email" 
                        [placeholder]="'contact_input_email' | translation: 'Email': []" />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item *ngIf="!isKering">
                    <nz-form-control purpleEditableText tranKey='contact_input_error_phone' [nzValidateStatus]="validateForm.controls['phoneNumber']"
                      [nzErrorTip]="'contact_input_error_phone' | translation: 'Numero di telefono obbligatorio!': []">
                      <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate" nzPrefixIcon="phone">
                        <ng-template #addOnBeforeTemplate>
                          <nz-select formControlName="phoneNumberPrefix" class="phone-select">
                            <nz-option *ngFor="let pref of phonePrefixes" [nzLabel]="pref" [nzValue]="pref"></nz-option>
                          </nz-select>
                        </ng-template>
                        <input purpleEditableText tranKey='contact_input_email' formControlName="phoneNumber" nz-input
                          [placeholder]="'contact_input_email' | translation: 'Telefono': []"
                           />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item>
                    <nz-form-control purpleEditableText tranKey='contact_input_error_message'
                      [nzErrorTip]="'contact_input_error_message' | translation: 'Messaggio obbliogatorio!': []">
                      <nz-input-group>
                        <textarea nz-input formControlName="message" purpleEditableText tranKey='contact_input_message'
                          [placeholder]="'contact_input_message' | translation: 'Messaggio': []"></textarea>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>

                  <button purpleEditableText tranKey='contact_send_message' nz-button class="purple-button submit-button ps-mt-1"
                    nzType="primary">{{ 'contact_send_message' |
                    translation:
                    'Invia messaggio': []}}</button>
                </form>
              </div>
            </div>
          </div>
          </nz-tab>

          <nz-tab [nzTitle]="'contacts_usefull_contact' | translation: 'Informazioni utili': []">
            <div
            class="auth-form-right-container ps-col-lg-5 ps-col-12 ps-d-flex ps-justify-content-center ps-align-items-start ps-justify-content-start">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap auth-form-right-container-box">

              <div *ngFor="let se of selectSaleEvents" class="ps-w-100  auth-form-right-container-contact-item">
                <h2 *ngIf="showAllSaleEventContacts" class="auth-form-right-container-contact-item-title">{{se.saleEventName | titlecase}}</h2>
                <div class="ps-w-100 ps-d-flex">
                  <div class="ps-col-6 ps-p-0">
                    <h3 purpleEditableText tranKey='contact_email' class="ps-w-100 auth-form-right-container-contact-item-field-title">
                      {{ 'contact_email' | translation: 'Email': []}}
                    </h3>
                    <a href="mailto:{{se.saleEventEmail}}" class="auth-form-right-container-contact-item-field-value">
                      {{se.saleEventEmail}}
                    </a>
                  </div>
                  <div class="ps-col-6 ps-p-0">
                    <h3 purpleEditableText tranKey='contact_phone' class="ps-w-100 auth-form-right-container-contact-item-field-title">
                      {{ 'contact_phone' | translation: 'Phone': []}}
                    </h3>
                    <a href="tel:{{se.saleEventPhone}}" class="auth-form-right-container-contact-item-field-value">
                      {{se.saleEventPhone}}
                    </a>
                  </div>
                </div>
                <div class="ps-col-12 ps-mt-3 ps-p-0">
                  <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                    <h3 purpleEditableText tranKey='contact_address' class="ps-w-100 auth-form-right-container-contact-item-field-title">
                      {{ 'contact_address' | translation: 'Indirizzo': []}}
                    </h3>
                    <a href="geo:{{se.addressLat}},{{se.addressLon}}" class="auth-form-right-container-contact-item-field-value">
                      {{se.addressFull}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </nz-tab>
        </nz-tabset>
      </div>
    </div>
  </div>
</div>
