<div class="book-slot-modal-container ps-d-flex ps-algin-items-start ps-w-100">
  <div
    class="ps-col-4 book-slot-modal-info-container ps-d-flex ps-flex-wrap ps-justify-content-center ps-align-items-start">
    <div class="book-slot-modal-stepper-container ps-d-flex ps-align-items-center ps-justify-content-center ps-w-100">
      <div class="book-slot-modal-step-item"></div>
      <div class="book-slot-modal-step-item"></div>
      <div class="book-slot-modal-step-item active"></div>
    </div>

    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center">
      <img class="book-slot-modal-info-sale-event-logo {{loadEnd?'load-end':''}}" contentId="{{book.saleEventId}}-logo"
        contentTypeId="sale-event-logo" purpleEditableImage />
      <h3 class="ps-w-100 book-slot-modal-info-order-detail-title {{loadEnd?'load-end':''}}">
        <!-- {{ this.step?this.steps.get(this.step)?.leftBannerTitle:""}} -->
        {{book.saleEventName}}
      </h3>
      <!-- <p class="book-slot-modal-info-order-detail-subtitle {{loadEnd?'load-end':''}} ps-m-0">
        {{ this.step?this.steps.get(this.step)?.leftBannerSubTitle:""}}
      </p> -->
    </div>
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-mt-auto">
      <h4 class="ps-w-100 book-slot-modal-info-problem" purpleEditableText tranKey='verify_order_have_problem'>{{ 'verify_order_have_problem' | translation:
        'Hai problemi?': []}}
      </h4>
      <p class="book-slot-modal-info-problem-subtitle ps-m-0" purpleEditableText tranKey='verify_order_have_problem_help_info'>{{ 'verify_order_have_problem_help_info' | translation:
        "Chiama {0} o contattaci all'indirizzo email {1} per aiutarti": [book.saleEventPhone, book.saleEventEmail]}}
      </p>
    </div>
  </div>
  <div class="ps-col-8 {{referralFriends==undefined?'ps-align-items-center ps-justify-content-center':'ps-align-items-start'}} ps-p-0 ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-container">

    <div class="ps-d-flex ps-justify-content-center ps-align-items-center book-slot-modal-detail-container-loader" *ngIf="referralFriends== undefined">
      <i nz-icon [nzType]="'loading'"></i>
    </div>

    <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-container-step">
      <div class="ps-w-100 ps-d-flex ps-align-items-center book-slot-modal-detail-header">
        <h2 purpleEditableText tranKey='book_slot_modal_share_title' class="book-slot-modal-detail-header-title ps-mr-auto {{loadEnd?'load-end':''}}">
          {{ 'book_slot_modal_share_title' | translation: 'Condividi Appuntamento': []}}
        </h2>
        <i (click)="closeModal()" class="ps-cursor-pointer" nz-icon nzType="close" nzTheme="outline"></i>
      </div>

      <div class="ps-d-flex ps-flex-wrap ps-w-100 book-slot-modal-detail-content {{loadEnd?'load-end':''}}" *ngIf="referralFriends!= undefined">
        <p class="book-slot-modal-detail-content-intro ps-m-0">
          <span purpleEditableText tranKey='book_slot_share_intro_limited' *ngIf="freeRefCodeNr > 0" 
            [innerHtml]="'book_slot_share_intro_limited' | translation: 'Puoi condividere la tua prenotazione con gli amici cliccando sui bottoni sottostanti. Hai ancora a disposizione <b>{0}</b> inviti': [freeRefCodeNr.toString()]">
          </span>
          <span purpleEditableText tranKey='book_slot_share_intro_free' *ngIf="freeRefCodeNr == -1">
            {{ 'book_slot_share_intro_free' | translation: 'Puoi condividere la tua prenotazione con gli amici cliccando sui bottoni sottostanti'}}
          </span>
          <span purpleEditableText tranKey='book_slot_share_intro_finish' *ngIf="freeRefCodeNr == 0">
            {{ 'book_slot_share_intro_finish' | translation: 'Hai terminato il numero amici che puoi invitare a questa private'}}
          </span>
        </p>

        <ng-container *ngIf="freeRefCodeNr > 0 || freeRefCodeNr == -1" >
          <h5 class="book-slot-modal-detail-content-title ps-w-100" purpleEditableText tranKey='book_slot_share_share'>
            {{ 'book_slot_share_share' | translation: 'Condividi con gli amici': []}}
          </h5>
          <div class="ps-w-100 book-slot-modal-share-buttons-container ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-start">
            <button (click)="sendEmail()" class="book-slot-modal-share-button" nzType="default" nz-button nzShape="circle">
              <i nz-icon nzType="mail" nzTheme="outline"></i>
            </button>
            <button (click)="sendWhatsapp()" class="book-slot-modal-share-button" nzType="default" nz-button nzShape="circle">
              <i nz-icon nzType="whats-app" nzTheme="outline"></i>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="(freeRefCodeNr > 0 || freeRefCodeNr == -1) || (freeRefCodeNr == 0 && referralFriends.length > 0)">
          <h5 class="book-slot-modal-detail-content-title ps-w-100" purpleEditableText tranKey='book_slot_share_share_friend_list'>
            {{ 'book_slot_share_share_friend_list' | translation: 'Amici invitati': []}}
          </h5>
          <div class="ps-w-100 book-slot-modal-share-friends-container ps-d-flex ps-flex-wrap ps-align-items-start ps-justify-content-center">
            <ng-container *ngIf="referralFriends.length > 0; else noFriends">
              <div class="ps-w-100 ps-d-flex ps-align-items-center ps-mb-2 ps-pt-2" *ngFor="let user of referralFriends">
                <i nz-icon nzType="user" nzTheme="outline"></i>
                <p class="ps-mb-0 ps-pb-0 ps-pl-2">{{user.firstName}} {{user.lastName}} ({{user.email}})</p>
              </div>
            </ng-container>
            <ng-template #noFriends>
              <p purpleEditableText tranKey='book_slot_share_share_friend_list_no_friends' class="ps-w-100 ps-pt-3">
                {{ 'book_slot_share_share_friend_list_no_friends' | translation: 'Ancora nessun amico ha accettato il tuo invito': []}}</p>
            </ng-template>
          </div>
        </ng-container>
        
      </div>
    </div>

    <div class="ps-w-100 ps-d-flex ps-mt-auto ps-justify-content-end ps-align-items-center book-slot-modal-detail-button-container" >
      
    
        <button (click)="closeModal()" class="book-slot-modal-detail-button sw-border-radius" nzType="primary" nz-button purpleEditableText tranKey='book_slot_info_close_button'>
          <i nz-icon nzType="close-square" nzTheme="outline"></i>
          {{ 'book_slot_info_close_button' | translation: 'Chiudi': []}}
        </button>

    </div>
  </div>

</div>
