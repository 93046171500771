import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Guid, PurpleEditService } from 'purple-lib';
import { Subscription } from 'rxjs';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PRPVSaleEventFull } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';

@Component({
  selector: 'app-request-change-email',
  templateUrl: './request-change-email.component.html',
  styleUrls: ['./request-change-email.component.scss']
})
export class RequestChangeEmailComponent implements OnInit, OnDestroy {
  validateForm!: UntypedFormGroup;
  isMobile: boolean = false;
  seId: string = "";
  subs: Subscription = new Subscription();

  async submitForm(): Promise<void> {
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {
      this.authenticationService.requestChangeEmail(this.validateForm.controls["email"].value, window.location.hostname, this.isMobile).then(
        async (isOk: boolean) => {
          if (isOk) {
            await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
          }
        }
      )
    }
  }

  constructor(private fb: UntypedFormBuilder, private authenticationService: AuthenticationService, private router: Router, private editSvc: PurpleEditService,
    private tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private breakpointObserver: BreakpointObserver) { }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    //console.log("current sale event:", this.seSvc.currentSaleEvent.value)

    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined) => {
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]]
    });

    if (this.authenticationService.isLoggedIn && !(this.editSvc.editModeOn.value && this.authenticationService.isAdminUser())) {
      await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
    }

    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 700px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    this.route.queryParams
      .subscribe(params => {
        this.seSvc.getSaleEventParameters(params);
      });
  }
}
