<div class="advanced-booking-sale-detail-banner-container ps-d-flex ps-flex-wrap"
  contentId="{{saleEventDetail!.saleEvent.saleEventId}}-banner" contentTypeId="sale-event-detail-banner-image"
  purpleEditableImage>
  <div
    class="advanced-booking-sale-detail-banner-dark-layer ps-d-flex ps-flex-wrap ps-w-100 ps-align-items-center ps-justify-content-center ps-position-relative">
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-w-100">
      <h1 class="advanced-sale-event-name ps-w-100 ps-text-center">
        {{saleEventDetail!.saleEvent.saleEventName}}
      </h1>
    </div>
  </div>
</div>
<div class="ps-w-100 ps-d-flex ps-justify-content-center">

  <!-- DESKTOP -->
  <div class="advanced-box-information-container ps-flex-wrap sw-border-radius">
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-w-100 ps-mb-4">
      <h1 class="advanced-sale-event-name ps-w-100 ps-text-center">
        {{saleEventDetail!.saleEvent.saleEventName}}
      </h1>
    </div>

    <div class="ps-d-flex ps-flex-wrap advanced-box-information-main-container">
      <h2 class="ps-w-100" purpleEditableText tranKey='advanced_sale_event_banner_about_sale_event'>
        {{ 'advanced_sale_event_banner_about_sale_event' | translation: 'A proposito della private sale:': []}}
      </h2>
      <p class="ps-p-0 ps-w-100" [innerHtml]="saleEventDetailText | safeHtml">

      </p>
    </div>
    <div class="ps-d-flex ps-flex-wrap advanced-box-information-side-container ps-align-items-start">

      <div class="ps-d-flex ps-flex-wrap">

        <h2 class="ps-w-100" purpleEditableText tranKey='advanced_sale_event_banner_when_sale_event'>
          {{ 'advanced_sale_event_banner_when_sale_event' | translation: 'Quando?': []}}
        </h2>
        <p purpleEditableText tranKey='advanced_sale_event_banner_when_2_sale_event' class="ps-p-0 ps-w-100 ps-mb-0"
          [innerHtml]="'advanced_sale_event_banner_when_2_sale_event' | translation: 'Dal {0} \</br> al {1}': [
        (saleEventDetail!.userSlotStartDate| saleEventDate:'dd MMMM')??'', (saleEventDetail!.userSlotEndDate| saleEventDate:'dd MMMM')??''
        ]">
        <h2 class="ps-w-100 where" purpleEditableText tranKey='advanced_sale_event_banner_where_sale_event'>
          {{ 'advanced_sale_event_banner_where_sale_event' | translation: 'Dove?': []}}
        </h2>
        <div class="ps-w-100 ps-d-flex ps-align-items-center">
          <i class="fsp-18-0 ps-mr-2" nz-icon nzType="home" nzTheme="outline"></i>
          <a [href]="'https://www.google.com/maps/search/?api=1&query='+saleEventDetail!.saleEvent.addressLat+'-'+saleEventDetail!.saleEvent.addressLon+
          '&query_place_id='+saleEventDetail!.saleEvent.addressExternalId" target="_blank" class="sale-event-address">
            {{ saleEventDetail!.saleEvent.addressName}}, {{ saleEventDetail!.saleEvent.addressNumber}}, {{
            saleEventDetail!.saleEvent.addressCity}} ({{ saleEventDetail!.saleEvent.addressProvince}})
          </a>
        </div>
        <ng-container *ngIf="checkCanInvite()">
          <h2 class="ps-w-100 invite" purpleEditableText tranKey='advanced_sale_event_banner_invite_sale_event'>
            {{ 'advanced_sale_event_banner_invite_sale_event' | translation: 'Invita Amici': []}}
          </h2>
          <div class="ps-w-100 ps-d-flex ps-justify-content-center">
            <button (click)="showInviteFriendsModal()" nz-button class="sw-border-radius ps-w-100" nzType="primary"
              purpleEditableText tranKey='invite_friend_detail_button'>
              {{ 'invite_friend_detail_button' | translation: 'Invita': []}}
            </button>
          </div>
        </ng-container>

      </div>
    </div>

  </div>

  <!-- TABLET -->
  <div class="tablet-advanced-box-information-container ps-flex-wrap sw-border-radius">
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-w-100 ps-mb-4">
      <h1 class="advanced-sale-event-name ps-w-100 ps-text-center">
        {{saleEventDetail!.saleEvent.saleEventName}}
      </h1>
    </div>
    <div class="ps-d-flex ps-flex-wrap advanced-box-information-main-container">
      <h2 class="ps-w-100" purpleEditableText tranKey='advanced_sale_event_banner_about_sale_event'>
        {{ 'advanced_sale_event_banner_about_sale_event' | translation: 'A proposito della private sale:': []}}
      </h2>
      <p class="ps-p-0 ps-w-100" [innerHtml]="saleEventDetailText">

      </p>
    </div>
    <div class="ps-d-flex ps-flex-wrap advanced-box-information-side-container ps-align-items-start">

      <div class="ps-d-flex ps-flex-wrap ps-col-6 ps-p-0">

        <h2 class="ps-w-100" purpleEditableText tranKey='advanced_sale_event_banner_when_sale_event'>
          {{ 'advanced_sale_event_banner_when_sale_event' | translation: 'Quando?': []}}
        </h2>
        <p purpleEditableText tranKey='advanced_sale_event_banner_when_2_sale_event' class="ps-p-0 ps-w-100" [innerHtml]="'advanced_sale_event_banner_when_2_sale_event' | translation: 'Dal {0} \</br> al {1}': [
        (saleEventDetail!.userSlotStartDate| saleEventDate:'dd MMMM')??'', (saleEventDetail!.userSlotEndDate| saleEventDate:'dd MMMM')??''
        ]">

      </div>

      <div class="ps-d-flex ps-flex-wrap ps-col-6 ps-p-0">
        <h2 class="ps-w-100" purpleEditableText tranKey='advanced_sale_event_banner_where_sale_event'>
          {{ 'advanced_sale_event_banner_where_sale_event' | translation: 'Dove?': []}}
        </h2>

        <div class="ps-w-100 ps-d-flex ps-align-items-center">
          <i class="fsp-16-0 ps-mr-2" nz-icon nzType="home" nzTheme="outline"></i>
          <a [href]="'https://www.google.com/maps/search/?api=1&query='+saleEventDetail!.saleEvent.addressLat+'-'+saleEventDetail!.saleEvent.addressLon+
          '&query_place_id='+saleEventDetail!.saleEvent.addressExternalId" target="_blank" class="sale-event-address">      
            <ng-container *ngIf="(saleEventDetail?.saleEvent?.addressExtra??undefined) == undefined">
              {{ saleEventDetail!.saleEvent.addressFull}}
            </ng-container>
            <ng-container *ngIf="(saleEventDetail?.saleEvent?.addressExtra??undefined) != undefined">
              {{ saleEventDetail!.saleEvent.addressExtra}}
            </ng-container>
          </a>
        </div>
      </div>

      <div *ngIf="checkCanInvite()" class="ps-col-12 ps-px-0 ps-mt-3 ps-d-flex ps-justify-content-center">
        <button (click)="showInviteFriendsModal()" nz-button class="sw-border-radius ps-w-100" nzType="primary"
          purpleEditableText tranKey='invite_friend_detail_button'>
          {{ 'invite_friend_detail_button' | translation: 'Invita Amico': []}}
        </button>
      </div>
    </div>
  </div>

  <!-- MOBILE -->
  <div class="mobile-advanced-box-information-container ps-flex-wrap sw-border-radius">
    <div class="ps-d-flex ps-flex-wrap advanced-box-information-side-container ps-align-items-start">

      <div class="ps-d-flex ps-flex-wrap ps-col-12 ps-pb-3 ps-pt-4">
        <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-w-100">
          <h1 class="advanced-sale-event-name ps-w-100 ps-text-center">
            {{saleEventDetail!.saleEvent.saleEventName}}
          </h1>
        </div>

        <h2 class="ps-w-100 where ps-text-center" purpleEditableText tranKey='advanced_sale_event_banner_where_sale_event'>
          {{ 'advanced_sale_event_banner_where_sale_event' | translation: 'Dove?': []}}
        </h2>


        <div class="ps-w-100 ps-d-flex ps-align-items-start ps-justify-content-center ps-pb-3">
          <i class="fsp-14-0 ps-mr-2" nz-icon nzType="home" nzTheme="outline"></i>
          <a [href]="'https://www.google.com/maps/search/?api=1&query='+saleEventDetail!.saleEvent.addressLat+'-'+saleEventDetail!.saleEvent.addressLon+
          '&query_place_id='+saleEventDetail!.saleEvent.addressExternalId" target="_blank"
            class="sale-event-address ps-text-center ps-text-center ">
            {{ saleEventDetail!.saleEvent.addressName}}, {{ saleEventDetail!.saleEvent.addressNumber}}, {{
            saleEventDetail!.saleEvent.addressCity}} ({{ saleEventDetail!.saleEvent.addressProvince}})
          </a>
        </div>
      </div>
      <div class="ps-d-flex ps-flex-wrap ps-col-12 ps-pb-4">

        <h2 class="ps-w-100 ps-text-center" purpleEditableText tranKey='advanced_sale_event_banner_when_sale_event'>
          {{ 'advanced_sale_event_banner_when_sale_event' | translation: 'Quando?': []}}
        </h2>
        <div purpleEditableText tranKey='advanced_sale_event_banner_when_2_sale_event' class="ps-p-0 ps-w-100 ps-text-center"
          [innerHtml]="'advanced_sale_event_banner_when_2_sale_event' | translation: 'Dal {0} \</br> al {1}': [
        (saleEventDetail!.userSlotStartDate| saleEventDate:'dd MMMM')??'', (saleEventDetail!.userSlotEndDate| saleEventDate:'dd MMMM')??'']">
        </div>

      </div>

    </div>
    <div class="ps-d-flex ps-flex-wrap advanced-box-information-main-container">
      <h2 class="ps-w-100" purpleEditableText tranKey='advanced_sale_event_banner_about_sale_event'>
        {{ 'advanced_sale_event_banner_about_sale_event' | translation: 'A proposito della private sale:': []}}
      </h2>
      <p class="ps-p-0 ps-w-100" [innerHtml]="saleEventDetailMobileText">

      </p>
      <div *ngIf="showMoreEnable" class="ps-w-100">
        <a (click)="showMore()" class="ps-m-0 ps-p-0">
          {{showMoreButtonText}}
          <i nz-icon nzType="arrow-up" class="ant-collapse-arrow" [nzRotate]="!showMoreExpanded ? 180 : 0"></i>
        </a>
      </div>
    </div>

  </div>

</div>