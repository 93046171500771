import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import { hamburgerAnimation } from 'src/core-modules/animations/hamburger-animation';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';
import { UserMenuDrawerComponent } from '../../drawers/user-menu-drawer/user-menu-drawer.component';
import { LayoutService } from '../../layout.service';
import { Subscription } from 'rxjs';
import { PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';
import { QrCodeDrawerComponent } from 'src/app/private/drawers/qr-code-drawer/qr-code-drawer.component';

@Component({
  selector: 'app-private-header',
  templateUrl: './private-header.component.html',
  styleUrls: ['../header-general.scss', './private-header.component.scss'],
  animations: [hamburgerAnimation]
})
export class PrivateHeaderComponent implements OnInit, OnDestroy {

  constructor(public loaderSvc: PurpleLoaderService, public tsvc: AppTranslationService, private router: Router, private modal: NzModalService, private drawerSvc: NzDrawerService,
    public authSvc: AuthenticationService, private tranPipe: PurpleTranslationPipe, private mesSvc: NzMessageService, public layoutSvc: LayoutService, private breakpointObserver: BreakpointObserver,
    private notSvc: NzNotificationService) { }


  isCollapsed = false;
  isHamburguer = true;
  menuMobileVisible = false;
  mobileMenuClass = 'close';
  isMobile: boolean = false;
  userMenuDrawer: NzDrawerRef<UserMenuDrawerComponent, string> | undefined;
  subs: Subscription = new Subscription();
  qrCodeOpen: boolean = false;
  //prova: boolean = false;

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 990px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    this.subs.add(this.layoutSvc.qrCodeOpen.subscribe((s: boolean) => {
      this.qrCodeOpen = s;
    }
    ));

    //console.log("E' mobile: ", this.isMobile)
  }

  hamburgerClick() {
    if (this.menuMobileVisible) {
      this.mobileMenuClass = 'close'
      setTimeout(() => {
        this.isHamburguer = !this.isHamburguer;
        this.menuMobileVisible = !this.menuMobileVisible;
      }, 300);
    } else {
      this.isHamburguer = !this.isHamburguer;
      this.menuMobileVisible = !this.menuMobileVisible;
      setTimeout(() => {
        this.mobileMenuClass = 'open'
      }, 300);
    }

  }

  async mobileNavigate(url: string[]) {
    this.isHamburguer = true;
    this.menuMobileVisible = false;
    await this.router.navigate(url);
  }

  async changeLanguage(lan: string) {
    await this.tsvc.switchLanguage(lan);
    this.mobileMenuClass = 'close'

    this.isHamburguer = !this.isHamburguer;
    this.menuMobileVisible = !this.menuMobileVisible;

  }

  logout(isMobile: boolean) {
    this.layoutSvc.isUserMenuOpen = false;
    this.modal.create(
      {
        nzTitle: this.tranPipe.transform("modal_logout_title", "Logout"),
        nzContent: this.tranPipe.transform("modal_logout_subtitle", "{0} sei sicuro di voler uscire?", [this.authSvc.currentUser?.user?.firstName!]),
        nzWidth: isMobile ? '80%' : '500px',
        nzClassName: 'ps-modal full-button',
        nzCentered: isMobile ? true : false,
        nzClosable: true,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("modal_logout_button", "logout"),
        nzCancelDisabled: true,
        nzCancelText: null,
        nzZIndex: 1000,
        nzOnOk: async () => {
          var res = this.authSvc.logout()

          if (res) {
            this.mesSvc.success(this.tranPipe.transform("message_logout_ok_response", "Logout effettuato correttamente"), {
              nzDuration: environment.MESSAGE_DURATION
            })
            if (isMobile) {
              this.isHamburguer = true;
              this.menuMobileVisible = false;
            }
            if (this.userMenuDrawer != undefined) {
              this.userMenuDrawer.close();
            }
            this.router.navigate([this.tsvc.currentLanguage.value, 'login'], {})
          } else {
            this.notSvc.error(this.tranPipe.transform('erro_message_default_title', 'Errore', []), this.tranPipe.transform("message_logout_error_response", "Errore! non è stato possibile effettuare il logout"),
              { nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top" });
          }
        }
      }
    )
  }

  showQrCodeDrawer() {
    this.layoutSvc.qrCodeOpen.next(true);
    this.drawerSvc.create<QrCodeDrawerComponent, { value: string }, string>({
      nzTitle: undefined,
      nzContent: QrCodeDrawerComponent,
      nzClosable: true,
      nzWrapClassName: "qr-code",
      nzFooter: undefined,
      nzPlacement: 'bottom'
    }).afterClose.subscribe(() => {
      this.layoutSvc.qrCodeOpen.next(false);
    })
  }

  showUserMenuDrawer() {
    this.userMenuDrawer = this.drawerSvc.create<UserMenuDrawerComponent, { value: string }, string>({
      nzTitle: undefined,
      nzContent: UserMenuDrawerComponent,
      nzClosable: true,
      nzWrapClassName: "user-menu-drawer",
      nzFooter: undefined,
      nzHeight: "260px",
      nzContentParams: {
        logout: () => this.logout(true),
      },
      nzPlacement: 'bottom'
    });
  }

  async navigateToHome() {
    await this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'private-sales']);
  }

}

