<div class="book-slot-drawer-container ps-d-flex ps-algin-items-start ps-w-100">
  <div class="ps-d-flex ps-flex-wrap ps-align-items-start">
    <div class="ps-d-flex ps-justify-content-center ps-align-items-center book-slot-drawer-detail-container-loader ps-w-100 ps-h-100"
    *ngIf="referralFriends==undefined">
    <i nz-icon [nzType]="'loading'"></i>
  </div>

  <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-drawer-detail-container-step">
    <div class="ps-w-100 ps-d-flex ps-align-items-center book-slot-drawer-detail-header">
      <h2 purpleEditableText tranKey='book_slot_drawer_share_title' class="book-slot-drawer-detail-header-title ps-mr-auto {{loadEnd?'load-end':''}}">
        {{ 'book_slot_drawer_share_title' | translation: 'Condividi Appuntamento': []}}
      </h2>
      <i (click)="closeDrawer()" class="ps-cursor-pointer" nz-icon nzType="close" nzTheme="outline"></i>
    </div>

    <div class="ps-d-flex ps-flex-wrap ps-w-100 book-slot-drawer-detail-content {{loadEnd?'load-end':''}}" *ngIf="referralFriends!= undefined">
      <p class="book-slot-drawer-detail-content-intro ps-m-0" purpleEditableText tranKey='book_slot_info_intro'>
        <span purpleEditableText tranKey='book_slot_share_intro_limited' *ngIf="freeRefCodeNr > 0" 
          [innerHtml]="'book_slot_share_intro_limited' | translation: 'Puoi condividere la tua prenotazione con gli amici cliccando sui bottoni sottostanti. Hai ancora a disposizione <b>{0}</b> inviti': [freeRefCodeNr.toString()]">
        </span>
        <span purpleEditableText tranKey='book_slot_share_intro_free' *ngIf="freeRefCodeNr == -1">
          {{ 'book_slot_share_intro_free' | translation: 'Puoi condividere la tua prenotazione con gli amici cliccando sui bottoni sottostanti'}}
        </span>
        <span purpleEditableText tranKey='book_slot_share_intro_finish' *ngIf="freeRefCodeNr == 0">
          {{ 'book_slot_share_intro_finish' | translation: 'Hai terminato il numero amici che puoi invitare a questa private'}}
        </span>
      </p>
      
      <ng-container *ngIf="freeRefCodeNr > 0 || freeRefCodeNr == -1" >
        <h5 class="book-slot-drawer-detail-content-title ps-w-100" purpleEditableText tranKey='book_slot_share_share'>
          {{ 'book_slot_share_share' | translation: 'Condividi con gli amici': []}}
        </h5>
        <div class="ps-w-100 book-slot-drawer-share-buttons-container ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center">
          <button (click)="sendEmail()" class="book-slot-drawer-share-button sw-border-radius" nzType="default" nz-button nzShape="circle">
            <i nz-icon nzType="mail" nzTheme="outline"></i>
          </button>
         
          <button (click)="genericShare()" class="book-slot-drawer-share-button sw-border-radius" nzType="default" nz-button nzShape="circle">
            <i nz-icon nzType="share-alt" nzTheme="outline"></i>
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="(freeRefCodeNr > 0 || freeRefCodeNr == -1) || (freeRefCodeNr == 0 && referralFriends.length > 0)">
        <h5 class="book-slot-drawer-detail-content-title ps-w-100" purpleEditableText tranKey='book_slot_share_share_friend_list'>
          {{ 'book_slot_share_share_friend_list' | translation: 'Amici invitati': []}}
        </h5>
        <div class="ps-w-100 book-slot-drawer-share-friends-container ps-d-flex ps-flex-wrap ps-align-items-start ps-justify-content-center">
          <ng-container *ngIf="referralFriends.length > 0; else noFriends">
            <div class="ps-w-100 ps-d-flex ps-align-items-center ps-mb-2 ps-pt-2" *ngFor="let user of referralFriends">
              <i nz-icon nzType="user" nzTheme="outline"></i>
              <p class="ps-mb-0 ps-pb-0 ps-pl-2">{{user.firstName}} {{user.lastName}} ({{user.email}})</p>
            </div>
          </ng-container>
          <ng-template #noFriends>
            <p purpleEditableText tranKey='book_slot_share_share_friend_list_no_friends' class="ps-w-100 ps-pt-3">
              {{ 'book_slot_share_share_friend_list_no_friends' | translation: 'Ancora nessun amico ha accettato il tuo invito': []}}</p>
          </ng-template>
        </div>
      </ng-container>
    </div>

  </div>

</div>
