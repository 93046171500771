import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { SaleEventService } from '../sale-event-service.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { RequestChangeEmailComponent } from './request-change-email/request-change-email.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { VerifyUserEmailComponent } from './verify-user-email/verify-user-email.component';
import { RegisterComponent } from './register/register.component';
import { PrivateRegisterComponent } from './private-register/private-register.component';
import { PurplePipeModule, PurpleSelectModule, Guid, PurpleGeneralDirectiveModule, PurpleEditImageModule, PurpleEditTextModule } from 'purple-lib';

@NgModule({
  declarations: [
    ForgotPasswordComponent,
    LoginComponent,
    ResetPasswordComponent,
    RequestChangeEmailComponent,
    ChangeEmailComponent,
    VerifyUserEmailComponent,
    RegisterComponent,
    PrivateRegisterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzLayoutModule,
    PurplePipeModule,
    NzDropDownModule,
    PurpleGeneralDirectiveModule,
    RouterModule,
    PurpleSelectModule,
    NzIconModule,
    NzNotificationModule,
    PurpleEditImageModule,
    PurpleEditTextModule
  ],
  providers:[
    SaleEventService,
    Guid
  ]
})
export class AuthenticationModule { }
