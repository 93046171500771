<div class="register-external-container ps-d-flex ps-flex-wrap">

    <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication"
      contentTypeId="authentication-image-mobile" purpleEditableImage>
      <div
        class="auth-form-mobile-banner-opacity-layer ps-w-100 ps-p-0 ps-h-100 ps-d-flex ps-justify-content-start ps-align-items-center">
        <div class="ps-col-6 ps-pl-4 ps-pr-0">
          <h1 *ngIf="titleText!='#NS#'" purpleEditableText tranKey='register_title_new' class="auth-form-title ps-w-100">
            {{titleText}}</h1>
        </div>
      </div> 
    </div>
  
    <div
      class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
      <div class="ps-d-flex ps-w-100 ps-flex-wrap">
        <h1 *ngIf="titleText!='#NS#'" purpleEditableText tranKey='register_title_new'
          class="auth-form-title ps-mb-0 ps-w-100 ps-d-lg-block ps-d-none purple-title">{{titleText}}</h1>
        <h2 purpleEditableText tranKey='register_subtitle' [innerHtml]="subtitleText | safeHtml"
          class="auth-form-subtitle ps-w-100 ps-d-lg-block ps-d-none purple-subtitle"></h2>
          <p *ngIf="subSubtitleText!='#NS#'" purpleEditableText tranKey='register_if_not_saywow_account' class="ps-w-100 auth-form-login-with-saywow-account ps-mt-lg-2 ps-mt-0">
            {{subSubtitleText}}</p>
        <div class="ps-col-12 ps-px-0 ps-d-flex ps-align-items-start">
  
  
          <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
  
            <nz-form-item>
              <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
                <nz-input-group nzPrefixIcon="user">
                  <input purpleEditableText tranKey='register_form_firstname' type="text" nz-input formControlName="firstName" id="firstName"
                    [placeholder]="'register_form_firstname' | translation: 'Nome': []" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
                <nz-input-group nzPrefixIcon="user">
                  <input purpleEditableText tranKey='register_form_lastname' type="text" nz-input formControlName="lastName" id="lastName"
                    [placeholder]="'register_form_lastname' | translation: 'Cognome': []" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-control [nzErrorTip]="errorTplEmail">
                <nz-input-group nzPrefixIcon="mail">
                  <input purpleEditableText tranKey='register_form_email' type="email" id="email" nz-input formControlName="email"
                    [placeholder]="'register_form_email' | translation: 'Email': []" />
                </nz-input-group>
                <ng-template #errorTplEmail let-control>
                  <ng-container purpleEditableText tranKey='required_input_error' *ngIf="control.hasError('required')">
                    {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
                  </ng-container>
                  <ng-container purpleEditableText tranKey='input_error_email_invalid' *ngIf="control.hasError('format')">
                    {{ 'input_error_email_invalid' | translation: 'Formato email non valido':
                    []}}
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-control purpleEditableText tranKey='required_input_error' [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
                <nz-input-group nzPrefixIcon="man">
  
                  <nz-select purpleEditableText tranKey='edit_user_gender_placeholder_new' class="address-select" [nzPlaceHolder]="'edit_user_gender_placeholder_new' | translation: 'Genere': []"
                    formControlName="gender">
                    <nz-option nzValue="M" [nzLabel]="'register_form_gender_man' | translation: 'Uomo': []"></nz-option>
                    <nz-option nzValue="F" [nzLabel]="'register_form_gender_female' | translation: 'Donna': []">
                    </nz-option>
                    <nz-option nzValue="NS" [nzLabel]="'register_form_gender_not_specified' | translation: 'Non Specificato': []">
                    </nz-option>
                  </nz-select>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
                <nz-input-group nzPrefixIcon="home" >
                  <ng-template #before>
                    <i class="fsp-15-0" nz-icon nzType="home" nzTheme="outline"></i>
                    <sup>*</sup>
                  </ng-template>
                  <purple-select [searchNameFunction]="addressSearchFunction" formControlName="address"
                    ngDefaultControl [searchIdFunction]="addressSearchIdFunction" cssClass="address-select"
                    [ngModel]="addressId" [placeholder]="fullAddressPlaceholder"
                    (onValueChange)="addressId = $event">
                  </purple-select>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
               
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate" nzPrefixIcon="phone">
  
  
                  <ng-template #addOnBeforeTemplate>
                    <nz-select nzShowSearch nzAllowClear formControlName="phoneNumberPrefix" class="phone-select">
                      <nz-option *ngFor="let pref of phonePrefixes" [nzLabel]="pref" [nzValue]="pref"></nz-option>
                    </nz-select>
                  </ng-template>

                  <input purpleEditableText tranKey='register_form_phone' [placeholder]="'register_form_phone' | translation: 'Telefono': []" formControlName="phoneNumber"
                    id="'phoneNumber'" nz-input />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-control [nzErrorTip]="errorTplPass">
                <nz-input-group nzPrefixIcon="lock">
                  <input purpleEditableText tranKey='register_form_password' type="password" id="password" nz-input formControlName="password"
                    [placeholder]="'register_form_password' | translation: 'Password': []" />
                </nz-input-group>
  
                <ng-template #errorTplPass let-control>
                  <ng-container purpleEditableText tranKey='required_input_error' *ngIf="control.hasError('required')">
                    {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
                  </ng-container>
                  <ng-container purpleEditableText tranKey='input_error_password_incosistent1' *ngIf="control.hasError('format')">
                    {{ 'input_error_password_incosistent1' 
                    | translation: 'La password deve contenere almeno 8 caratteri, una lettera maiuscola e un carattere speciale (,;.!@#$%^&*)': []}}
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
  
            <nz-form-item>
              <nz-form-control [nzErrorTip]="errorTpl">
                <nz-input-group nzPrefixIcon="lock">
                  <input purpleEditableText tranKey='register_form_confirm_password' [placeholder]="'register_form_confirm_password' | translation: 'Conferma Password': []" nz-input
                    type="password" nz-input formControlName="checkPassword" id="checkPassword" />
                </nz-input-group>
  
                <ng-template #errorTpl let-control>
                  <ng-container purpleEditableText tranKey='input_error_confirm_password_empty' *ngIf="control.hasError('required')">
                    {{ 'input_error_confirm_password_empty' | translation: 'Conferma la password': []}}
                  </ng-container>
                  <ng-container purpleEditableText tranKey='input_error_confirm_password_incosistent' *ngIf="control.hasError('confirm')">
                    {{ 'input_error_confirm_password_incosistent' | translation: 'Le password inserite non corrispondono':
                    []}}
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
  
            <ng-container *ngFor="let policy of policies">
              <container-element [ngSwitch]="policy.policyTypeId">
  
                <ng-container *ngSwitchCase="'defualt'">
                  <nz-form-item class="register-area">
                    <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <label nz-checkbox [formControlName]="policy.policyId">
                      </label>
                      <p class="ps-mm-0 ps-pb-0 ps-pl-3">
                        {{policy.policyTitle}}
                      </p>
                      <span *ngIf="policy.isMandatoryPolicy">
                        <sup>*</sup>
                      </span>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
  
  
                <ng-container *ngSwitchCase="'iubenda'">
                  <nz-form-item class="register-area">
                    <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <label nz-checkbox [formControlName]="policy.policyId">
                      </label>
                      <span purpleEditableText class="ps-mm-0 ps-pb-0 ps-pl-3">
                        {{ 'accept' | translation: 'Accetto l\'': []}}<a [href]='policy.policyUrl' class='iubenda iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe'>{{policy.policyTitle}}</a>
                      </span>
                      <span *ngIf="policy.isMandatoryPolicy">
                        <sup>*</sup>
                      </span>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
  
                <ng-container *ngSwitchCase="'text'">
                  <nz-form-item class="register-area">
                    <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <label nz-checkbox [formControlName]="policy.policyId">
                      </label>
                      <p class="ps-mm-0 ps-pb-0 ps-pl-3">
                        {{policy.policyText}}
                      </p>
                      <span *ngIf="policy.isMandatoryPolicy">
                        <sup>*</sup>
                      </span>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
  
                <ng-container *ngSwitchCase="'media'">
                  <nz-form-item class="register-area">
                    <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <label nz-checkbox [formControlName]="policy.policyId">
                      </label>
                      <span purpleEditableText class="ps-mm-0 ps-pb-0 ps-pl-3">
                        {{ 'accept' | translation: 'Accetto l\'': []}}<a [href]='policySvc.getPolicyImageUrl(policy)' 
                        class='iubenda iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe'>{{policy.policyTitle}}</a>
                      </span>
                      <span *ngIf="policy.isMandatoryPolicy">
                        <sup>*</sup>
                      </span>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
  
                <ng-container *ngSwitchCase="'html'">
                  <nz-form-item class="register-area">
                    <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <label nz-checkbox [formControlName]="policy.policyId">
                      </label>
                      <span class="ps-mm-0 ps-pb-0 ps-pl-3" [innerHtml]="policy.policyHtml??'' | safeHtml">
                      </span>
                      <span *ngIf="policy.isMandatoryPolicy">
                        <sup>*</sup>
                      </span>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
  
              </container-element>
            </ng-container>
  
            
            
  
  
            <button purpleEditableText tranKey='register_register_button' nz-button class="submit-button purple-button" nzType="primary">{{ 'register_register_button' |
              translation: 'Registrati': []}}</button>
            <div class="ps-mt-3 ps-w-100 ps-d-flex ps-align-items-center ps-mb-4">
              <p purpleEditableText tranKey='register_have_you_an_account' class="ps-mb-0 ps-pr-3 fsp-16-0">{{ 'register_have_you_an_account' | translation: 'Hai già un account?':
                []}}</p>
              <button purpleEditableText tranKey='register_login' class="sw-border-radius" [routerLink]="['/',tsvc.currentLanguage.value, 'login']" nz-button nzType="default">{{
                'register_login' | translation: 'Accedi': []}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  
    <div
      class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex ps-justify-content-center ps-align-items-end ps-justify-content-start">
      <div class="auth-form-vertical-image" contentId="{{seId}}-authentication" contentTypeId="authentication-image"
        purpleEditableImage>
      </div>
    </div>
  
  </div>