import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storageCache: Map<string, any> = new Map<string, any>();
  clearCache(): void {
    this.storageCache.clear();
  }
  private getStorage(mode: string = 'local'): Storage {
    return mode === 'local' ? localStorage : sessionStorage;
  }

  set(key: string, obj: any, mode: string = 'local'): boolean {
    try {
      this.storageCache.set(key, obj);
      const storage = this.getStorage(mode);
      storage.setItem(key, JSON.stringify(obj,(k, v) => v === undefined ? null : v));
      return true;
    } catch (error) {
      return false;
    }
  }

  get<T>(key: string, mode: string = 'local'): T {
    try {
      if (this.storageCache.has(key)) {
        return this.storageCache.get(key) as T;
      }
      const storageItem = this.getStorage(mode).getItem(key);
      const obj = JSON.parse(storageItem!) as T;
      if (obj) {
        this.storageCache.set(key, obj);
      }
      return obj;
    } catch (error) {
      throw error;
    }
  }

  remove(key: string, mode: string = 'local'): boolean {
    try {
      const storage = this.getStorage(mode);
      this.storageCache.delete(key);
      storage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  }

  clear(mode: string = 'local'): boolean {
    try {
      const storage = this.getStorage(mode);
      storage.clear();
      this.clearCache();
      return true;
    } catch (error) {
      return false;
    }
  }

  constructor() {

  }
}
