<div class="booking-sale-detail-banner-container ps-d-flex ps-flex-wrap"
  contentId="{{saleEventDetail!.saleEvent.saleEventId}}-banner" contentTypeId="sale-event-detail-banner-image"
  purpleEditableImage>
  <div
    class="booking-sale-detail-banner-dark-layer ps-d-flex ps-flex-wrap ps-w-100 ps-align-items-center ps-justify-content-center ps-position-relative">
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-w-100">
      <h1 class="ps-w-100 sale-event-city ps-text-center">
        {{saleEventDetail!.saleEvent.addressCity??"Non specificata"}}
      </h1>
      <h3 class="sale-event-name ps-w-100 ps-text-center">
        {{saleEventDetail!.saleEvent.saleEventName}}
      </h3>
      <p class="sale-event-dates ps-w-100 ps-text-center">
        {{ saleEventDetail!.userSlotStartDate| saleEventDate:"dd MMMM"}} -
        {{ saleEventDetail!.userSlotEndDate | saleEventDate:"dd MMMM"}}
      </p>
      <div class="ps-w-100 ps-d-flex ps-justify-content-center ps-align-items-center">
        <a [href]="'https://www.google.com/maps/search/?api=1&query='+saleEventDetail!.saleEvent.addressLat+'-'+saleEventDetail!.saleEvent.addressLon+
          '&query_place_id='+saleEventDetail!.saleEvent.addressExternalId" target="_blank"
          class="sale-event-address ps-text-center">
          {{saleEventDetail.saleEvent.addressExtra}} <ng-container *ngIf="(saleEventDetail?.saleEvent?.addressExtra??undefined)!= undefined">-</ng-container> {{ saleEventDetail!.saleEvent.addressName}}, {{ saleEventDetail!.saleEvent.addressNumber}}, {{
          saleEventDetail!.saleEvent.addressCity}} ({{ saleEventDetail!.saleEvent.addressProvince}})
        </a>
      </div>
      <button *ngIf="checkCanInvite()" (click)="showInviteFriendsModal()" nz-button class="base-banner-invite-friend-button ps-d-md-block ps-d-none sw-border-radius ps-w-100" nzType="primary" purpleEditableText
        tranKey='invite_friend_detail_button'>
        {{ 'invite_friend_base_detail_button' | translation: 'Invita Amici': []}}
      </button>
    </div> 
    
    <!-- <button (click)="scrollFn()" nz-button nzType="default" class="sale-event-scroll-down-button">
      <i nz-icon nzType="arrow-down" nzTheme="outline"></i>
    </button> -->
  </div>
</div>