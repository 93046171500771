<div class="book-slot-modal-container ps-d-flex ps-algin-items-start ps-w-100">
  <div
    class="ps-col-4 book-slot-modal-info-container ps-d-flex ps-flex-wrap ps-justify-content-center ps-align-items-start">
    <div class="book-slot-modal-stepper-container ps-d-flex ps-align-items-center ps-justify-content-center ps-w-100">
      <div class="book-slot-modal-step-item {{step==1?'active':''}}"></div>
      <div class="book-slot-modal-step-item {{step==2?'active':''}}"></div>
      <div class="book-slot-modal-step-item {{step==3?'active':''}}"></div>
    </div>

    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center">
      <img class="book-slot-modal-info-sale-event-logo {{loadEnd?'load-end':''}}" contentId="{{(seSvc.currentSaleEvent | async)?.saleEventId??empty}}-logo"
        contentTypeId="sale-event-logo" purpleEditableImage />
      <h3 class="ps-w-100 book-slot-modal-info-order-detail-title {{loadEnd?'load-end':''}}">
        <!-- {{ this.step?this.steps.get(this.step)?.leftBannerTitle:""}} -->
        {{data.saleEventName}}
      </h3>
      <!-- <p class="book-slot-modal-info-order-detail-subtitle {{loadEnd?'load-end':''}} ps-m-0">
        {{ this.step?this.steps.get(this.step)?.leftBannerSubTitle:""}}
      </p> -->
    </div>
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-mt-auto">
      <h4 class="ps-w-100 book-slot-modal-info-problem" purpleEditableText tranKey='verify_order_have_problem'>{{ 'verify_order_have_problem' | translation:
        'Hai problemi?': []}}
      </h4>
      <p class="book-slot-modal-info-problem-subtitle ps-m-0" purpleEditableText tranKey='verify_order_have_problem_help_info'>{{ 'verify_order_have_problem_help_info' | translation:
        "Chiama {0} o contattaci all'indirizzo email {1} per aiutarti": [(seSvc.currentSaleEvent | async)?.saleEventPhone??'', (seSvc.currentSaleEvent | async)?.saleEventEmail??'']}}
      </p>
    </div>
  </div>
  <div class="ps-col-8 {{step==undefined?'ps-align-items-center ps-justify-content-center':'ps-align-items-start'}} ps-p-0 ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-container">

    <div class="ps-d-flex ps-justify-content-center ps-align-items-center book-slot-modal-detail-container-loader" *ngIf="step==undefined">
      <i nz-icon [nzType]="'loading'"></i>
    </div>

    <div class="ps-w-100 ps-d-flex ps-align-items-start ps-flex-wrap book-slot-modal-detail-container-step ps-h-100">
      <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-h-100">

      <div class="ps-w-100 ps-d-flex ps-align-items-center book-slot-modal-detail-header">
        <h2 *ngIf="step != undefined" purpleEditableText [tranKey]='this.steps.get(this.step)?.titleTranslationKey' class="book-slot-modal-detail-header-title ps-mr-auto {{loadEnd?'load-end':''}}">{{this.steps.get(this.step)?.titleText??""}}</h2>
        <i (click)="closeModal()" class="ps-cursor-pointer" nz-icon nzType="close" nzTheme="outline"></i>
      </div>

      <!-- STEP 1 -->
      <div class="ps-d-flex ps-flex-wrap ps-w-100 book-slot-modal-detail-content {{loadEnd?'load-end':''}}" *ngIf="step==1">
        <div class="ps-d-flex ps-flex-wrap ps-w-100">

        <p class="book-slot-modal-detail-content-intro ps-m-0" purpleEditableText tranKey='book_slot_info_intro'>
          {{ 'book_slot_info_intro' | translation: 'Rivedi i dati che hai inserito e conferma la tua prenotazione:': []}}
        </p>
        <h5 class="book-slot-modal-detail-content-title ps-w-100" purpleEditableText tranKey='book_slot_info_book'>
          {{ 'book_slot_info_book' | translation: 'Informazioni Appuntamento': []}}
        </h5>
        <div class="ps-d-flex ps-flex-wrap ps-w-100">
          <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pl-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_date'>{{ 'book_slot_info_date' | translation: 'Data': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{data.slot!.slotStartDate | saleEventDate: "longDate"}}</h3>
            </div>
          </div>
          <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pr-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_slot'>{{ 'book_slot_info_slot' | translation: 'Ora': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{data.slot!.slotStartDate | saleEventDate: "shortTime"}} - {{data.slot!.slotEndDate | saleEventDate: "shortTime"}}</h3>
            </div>
          </div>
          <div class="{{saleEventSlotBookingId!= undefined?'ps-col-6':'ps-col-12'}} ps-d-flex ps-flex-wrap ps-px-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_place'>{{ 'book_slot_info_place' | translation: 'Indirizzo': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{(seSvc.currentSaleEvent | async)?.addressFull}}</h3>
            </div>
          </div>
          <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pr-0" *ngIf="saleEventSlotBookingId!= undefined">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_actual_book'>{{ 'book_slot_info_actual_book' | translation: 'Prenotazione attuale': []}} <!-- <i nz-icon nzType="warning" nzTheme="outline"></i> --></p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{data.bookedSlot!.slotStartDate| saleEventDate: "longDate"}} 
                ({{data.bookedSlot!.slotStartDate| saleEventDate: "shortTime"}} - {{data.bookedSlot!.slotEndDate | saleEventDate: "shortTime"}})</h3>
            </div>
          </div>
        </div>
        <h5 class="book-slot-modal-detail-content-title more-space ps-w-100" purpleEditableText tranKey='book_slot_info_user'>
          {{ 'book_slot_info_user' | translation: 'Informazioni Cliente': []}}
        </h5>
        <div class="ps-d-flex ps-flex-wrap ps-w-100">
          <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pl-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_user_firstname'>{{ 'book_slot_info_user_firstname' | translation: 'Nome': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{authSvc.currentUser!.user!.firstName | titlecase}} {{authSvc.currentUser!.user!.lastName| titlecase}}</h3>
            </div>
          </div>
          <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pr-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
            <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_user_email'>{{ 'book_slot_info_user_email' | translation: 'Email': []}}</p>
            <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{authSvc.currentUser!.user!.email}}</h3>
          </div>
        </div>
          <div *ngIf="checkPhoneNumber()" class="ps-col-6 ps-d-flex ps-flex-wrap ps-pl-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_user_phone'>{{ 'book_slot_info_user_phone' | translation: 'Telefono': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{authSvc.currentUser!.user!.phone}}</h3>
            </div>
          </div>
          <div class="ps-col-6 ps-d-flex ps-flex-wrap {{checkPhoneNumber()?'ps-pr-0':'ps-pl-0'}}">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col no-border">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_user_handicap'>{{ 'book_slot_info_user_handicap' | translation: 'Mobilità ridotta': []}}</p>
              <div class="ps-w-100">
                <!-- <label nz-checkbox [(ngModel)]="handicap"></label> -->
                <nz-switch nzSize="default" [(ngModel)]="handicap"></nz-switch>
              </div>
            </div>
          </div>
        </div>
        <!-- <ng-container *ngIf="policies.length == 1">
        <div class="ps-w-100 ps-d-flex book-slot-modal-detail-privacy-title-container ps-align-items-center">
          <h5 class="book-slot-modal-detail-privacy-title" purpleEditableText tranKey='book_slot_policy'>
            {{ 'book_slot_policy' | translation: 'Condizioni di prenotazione': []}}
          </h5>
        </div>        
          <ng-container *ngFor="let policy of policies">
            <container-element [ngSwitch]="policy.policyTypeId">
              <ng-container *ngSwitchCase="'text'">
                <div class="ps-w-100 policy-container">
                  {{policy.policyText}}
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'html'">
                <div class="ps-w-100 policy-container" [innerHtml]="policy.policyHtml">

                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'iubenda'">
                <div class="ps-w-100 policy-container" purpleEditableText class="ps-mm-0 ps-pb-0">
                  <a [href]='policy.policyUrl' target="blank" class='iubenda iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe'>
                    {{policy.policyTitle}}
                  </a>
                </div>
              </ng-container>
            </container-element>
            <div class="ps-w-100 ps-d-flex">

              <label [ngModel]="false" (ngModelChange)="changePolicyCheck($event, policy.policyId)" nz-checkbox class="book-slot-drawer-detail-content-privacy-intro ps-m-0 ps-py-1 ps-w-100" purpleEditableText tranKey='book_slot_policy_intro'>
                {{ 'book_slot_policy_intro' | translation: 'Si accetta la seguente informativa': []}} <ng-container *ngIf="policy.isMandatoryPolicy"><sup>*</sup></ng-container>
              </label>
            </div>
          </ng-container>
          
        </ng-container> -->
      </div>

    </div>
      <!-- STEP 2 -->
      <div class="ps-d-flex ps-flex-wrap ps-w-100 book-slot-modal-detail-content {{loadEnd?'load-end':''}}" *ngIf="step==2">
        <div class="ps-d-flex ps-flex-wrap ps-w-100">
        <p class="book-slot-modal-detail-content-intro ps-m-0" purpleEditableText tranKey='book_slot_info_policy_intro'>
          {{ 'book_slot_info_policy_intro' | translation: 'Prendi visione delle seguenti informative:': []}}
        </p>
          <div class="ps-w-100 ps-d-flex book-slot-modal-detail-privacy-title-container ps-align-items-center">
            <h5 class="book-slot-modal-detail-privacy-title step-2" purpleEditableText tranKey='book_slot_policy'>
              {{ 'book_slot_policy' | translation: 'Condizioni di prenotazione': []}}
            </h5>
          </div>        
          <ng-container *ngFor="let policy of data.policies">
            <div class="policy-external-container ps-w-100 ps-d-flex ps-flex-wrap">
              <p *ngIf="policy.policyTitle" class="policy-step-due-title ps-mb-0 ps-w-100">
                {{policy.policyTitle | titlecase}}
              </p>
              <container-element [ngSwitch]="policy.policyTypeId">
                <ng-container *ngSwitchCase="'text'">
                  <div class="ps-w-100 policy-container">
                    {{policy.policyText}}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'html'">
                  <div class="ps-w-100 policy-container" [innerHtml]="policy.policyHtml">
    
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'iubenda'">
                  <div class="ps-w-100 policy-container" purpleEditableText class="ps-mm-0 ps-pb-0">
                    <a [href]='policy.policyUrl' target="blank" class='iubenda iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe'>
                      {{policy.policyTitle}}
                    </a>
                  </div>
                </ng-container>
              </container-element>
              <div class="ps-w-100 ps-d-flex ps-pt-2">
    
                <label [ngModel]="false" (ngModelChange)="changePolicyCheck($event, policy.policyId)" nz-checkbox class="book-slot-drawer-detail-content-privacy-intro ps-m-0 ps-py-1 ps-w-100" purpleEditableText tranKey='book_slot_policy_intro'>
                  {{ 'book_slot_policy_intro' | translation: 'Si accetta la seguente informativa': []}} <ng-container *ngIf="policy.isMandatoryPolicy"><sup>*</sup></ng-container>
                </label>
              </div>
            </div>
            
          </ng-container>
      </div>
    </div>
      <!-- STEP 3 -->
      <div class="ps-d-flex ps-flex-wrap ps-w-100 book-slot-modal-detail-content {{loadEnd?'load-end':''}}" *ngIf="step==3">
        <div class="ps-d-flex ps-flex-wrap ps-w-100">
        <div class="ps-d-flex ps-flex-wrap ps-w-100 book-slot-modal-detail-content-action-buttons">

            <!-- <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col ps-pt-2 no-border">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText 
              tranKey='book_slot_confirm'>{{ 'book_slot_confirm_crc' | translation: 'Conferma': []}}</p>
              <h1 class="book-slot-modal-detail-content-field-number ps-w-100 ps-m-0">
                {{ 'book_slot_confirm_text' | translation: 'Prenotazione avvenuta con successo': []}}
              </h1>
            </div> -->
              <a [href]="pdfSrc()" class="book-slot-modal-detail-button print-button" nzType="primary" nz-button purpleEditableText tranKey='book_slot_info_print_confirm'>
                <i nz-icon nzType="printer" nzTheme="outline"></i>
                {{ 'book_slot_info_print_confirm' | translation: 'Stampa Conferma': []}}
              </a>

              <a [href]="calendarSrc()" class="book-slot-modal-detail-button ps-ml-3 add-to-calendar-button" nz-button purpleEditableText tranKey='book_slot_info_add_to_calendar'>
                <i nz-icon nzType="calendar" nzTheme="outline"></i>
                {{ 'book_slot_info_add_to_calendar' | translation: 'Aggiungi al Calendario': []}}
            </a>
          
        </div>
        <h5 class="book-slot-modal-detail-content-title ps-w-100" purpleEditableText tranKey='book_slot_info_book'>
          {{ 'book_slot_info_book' | translation: 'Informazioni Appuntamento': []}}
        </h5>
        <div class="ps-d-flex ps-flex-wrap ps-w-100">
          <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pl-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_date'>{{ 'book_slot_info_date' | translation: 'Data': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{data.slot!.slotStartDate | saleEventDate: "longDate"}}</h3>
            </div>
          </div>
          <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pr-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_slot'>{{ 'book_slot_info_slot' | translation: 'Ora': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{data.slot!.slotStartDate | saleEventDate: "shortTime"}} - {{data.slot!.slotEndDate | saleEventDate: "shortTime"}}</h3>
            </div>
          </div>
          <div class="ps-col-12 ps-d-flex ps-flex-wrap ps-px-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_place'>{{ 'book_slot_info_place' | translation: 'Indirizzo': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{(seSvc.currentSaleEvent | async)?.addressFull}}</h3>
            </div>
          </div>
        </div>
        <h5 class="book-slot-modal-detail-content-title more-space ps-w-100" purpleEditableText tranKey='book_slot_info_user'>
          {{ 'book_slot_info_user' | translation: 'Informazioni Cliente': []}}
        </h5>
        <div class="ps-d-flex ps-flex-wrap ps-w-100"> 
          <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pl-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col" >
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_user_firstname'>{{ 'book_slot_info_user_firstname' | translation: 'Nome': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{authSvc.currentUser!.user!.firstName | titlecase}} {{authSvc.currentUser!.user!.lastName| titlecase}}</h3>
            </div>
          </div> 
          <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pr-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col" >
            <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_user_email'>{{ 'book_slot_info_user_email' | translation: 'Email': []}}</p>
            <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{authSvc.currentUser!.user!.email}}</h3>
          </div>
        </div>
          <div *ngIf="checkPhoneNumber()" class="ps-col-6 ps-d-flex ps-flex-wrap ps-pl-0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col" >
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_user_phone'>{{ 'book_slot_info_user_phone' | translation: 'Telefono': []}}</p>
              <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0">{{authSvc.currentUser!.user!.phone}}</h3>
            </div>
          </div>
          <div class="ps-col-6 ps-d-flex ps-flex-wrap {{checkPhoneNumber()?'ps-pr-0':'ps-pl-0'}}">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-content-col">
              <p class="book-slot-modal-detail-content-field-title ps-w-100 ps-m-0" purpleEditableText tranKey='book_slot_info_user_handicap'>{{ 'book_slot_info_user_handicap' | translation: 'Mobilità ridotta': []}}</p>
              <div class="ps-w-100">
                <!-- <label nz-checkbox [(ngModel)]="handicap"></label> -->
                <h3 class="book-slot-modal-detail-content-field-value ps-w-100 ps-m-0" purpleEditableText [tranKey]='handicap?"book_slot_info_user_handicap_true":"book_slot_info_user_handicap_false"'>{{handicap?('book_slot_info_user_handicap_true' | translation: 'Si')
                  :('book_slot_info_user_handicap_false' | translation: 'No')}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- STEP 4 -->
      <div class="ps-d-flex ps-flex-wrap ps-w-100 book-slot-modal-detail-content {{loadEnd?'load-end':''}}" *ngIf="step==4">
        <div class="ps-d-flex ps-flex-wrap ps-w-100">
        <p class="book-slot-modal-detail-content-intro ps-m-0">
          <span purpleEditableText tranKey='book_slot_share_intro_limited' *ngIf="freeRefCodeNr > 0" 
            [innerHtml]="'book_slot_share_intro_limited' | translation: 'Puoi condividere la tua prenotazione con gli amici cliccando sui bottoni sottostanti. Hai ancora a disposizione <b>{0}</b> inviti': [freeRefCodeNr.toString()]">
          </span>
          <span purpleEditableText tranKey='book_slot_share_intro_free' *ngIf="freeRefCodeNr == -1">
            {{ 'book_slot_share_intro_free' | translation: 'Puoi condividere la tua prenotazione con gli amici cliccando sui bottoni sottostanti'}}
          </span>
          <span purpleEditableText tranKey='book_slot_share_intro_finish' *ngIf="freeRefCodeNr == 0">
            {{ 'book_slot_share_intro_finish' | translation: 'Hai terminato il numero amici che puoi invitare a questa private'}}
          </span>
        </p>

        <ng-container *ngIf="freeRefCodeNr > 0 || freeRefCodeNr == -1" >
          <h5 class="book-slot-modal-detail-content-title ps-w-100" purpleEditableText tranKey='book_slot_share_share'>
            {{ 'book_slot_share_share' | translation: 'Condividi con gli amici': []}}
          </h5>
          <div class="ps-w-100 book-slot-modal-share-buttons-container ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-start">
            <button (click)="sendEmail()" class="book-slot-modal-share-button" nzType="default" nz-button nzShape="circle">
              <i nz-icon nzType="mail" nzTheme="outline"></i>
            </button>
            <button (click)="sendWhatsapp()" class="book-slot-modal-share-button" nzType="default" nz-button nzShape="circle">
              <i nz-icon nzType="whats-app" nzTheme="outline"></i>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="(freeRefCodeNr > 0 || freeRefCodeNr == -1) || (freeRefCodeNr == 0 && referralFriends.length > 0)">
          <h5 class="book-slot-modal-detail-content-title ps-w-100" purpleEditableText tranKey='book_slot_share_share_friend_list'>
            {{ 'book_slot_share_share_friend_list' | translation: 'Amici invitati': []}}
          </h5>
          <div class="ps-w-100 book-slot-modal-share-friends-container ps-d-flex ps-flex-wrap ps-align-items-start ps-justify-content-center">
            <div class="ps-d-flex ps-flex-wrap ps-w-100">
              <ng-container *ngIf="referralFriends.length > 0; else noFriends">
                <div class="ps-w-100 ps-d-flex ps-align-items-center ps-mb-2 ps-pt-2" *ngFor="let user of referralFriends">
                  <i nz-icon nzType="user" nzTheme="outline"></i>
                  <p class="ps-mb-0 ps-pb-0 ps-pl-2">{{user.firstName}} {{user.lastName}} ({{user.email}})</p>
                </div>
              </ng-container>
              <ng-template #noFriends>
                <p purpleEditableText tranKey='book_slot_share_share_friend_list_no_friends' class="ps-w-100 ps-pt-3">
                  {{ 'book_slot_share_share_friend_list_no_friends' | translation: 'Ancora nessun amico ha accettato il tuo invito': []}}</p>
              </ng-template>
            </div>            
          </div>
        </ng-container>
        
      </div>
    </div>
      </div>
    </div>

    <div class="ps-w-100 ps-d-flex ps-mt-auto ps-justify-content-end ps-align-items-center book-slot-modal-detail-button-container" >
      
      <button 
      *ngIf="step==1" class="book-slot-modal-detail-button sw-border-radius" nzType="primary" nz-button (click)="nextStep()">
        <p purpleEditableText [tranKey]='this.steps.get(this.step)?.nextButtonTranslationKey' *ngIf="this.step!= undefined; else buttonLoad" class="ps-mb-0">
          {{this.steps.get(this.step)?.nextButtonText}}
          <i nz-icon nzType="arrow-right"></i>
        </p>
        <ng-template #buttonLoad>
          <div class="pd-d-flex ps-justify-content-center ps-align-items-center">
            <i nz-icon [nzType]="'loading'"></i>
          </div>
        </ng-template>
      </button>

      <div class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-between" *ngIf="step==2">
        <button class="book-slot-modal-detail-button sw-border-radius" nzType="default" nz-button (click)="prevStep()">
          <p purpleEditableText [tranKey]='this.steps.get(this.step)?.prevButtonTranslationKey' *ngIf="this.step!= undefined; else buttonLoad" class="ps-mb-0">
            <i nz-icon nzType="arrow-left"></i>
            {{this.steps.get(this.step)?.prevButtonText}}
          </p>
          <ng-template #buttonLoad>
            <div class="pd-d-flex ps-justify-content-center ps-align-items-center">
              <i nz-icon [nzType]="'loading'"></i>
            </div>
          </ng-template>
        </button>

        <button [disabled]="!checkAllPolicies()" class="book-slot-modal-detail-button sw-border-radius" nzType="primary" nz-button (click)="nextStep()">
          <p purpleEditableText [tranKey]='this.steps.get(this.step)?.nextButtonTranslationKey' *ngIf="this.step!= undefined; else buttonLoad" class="ps-mb-0">
            {{this.steps.get(this.step)?.nextButtonText}}
            <i nz-icon nzType="arrow-right"></i>
          </p>
          <ng-template #buttonLoad>
            <div class="pd-d-flex ps-justify-content-center ps-align-items-center">
              <i nz-icon [nzType]="'loading'"></i>
            </div>
          </ng-template>
        </button>
       
      </div>

      

        <button *ngIf="step==3 && showInviteButton()" class="book-slot-modal-detail-button sw-border-radius" nzType="primary" nz-button (click)="nextStep()">
          <p purpleEditableText [tranKey]='this.steps.get(this.step)?.nextButtonTranslationKey' *ngIf="this.step!= undefined; else buttonLoad" class="ps-mb-0">
            {{this.steps.get(this.step)?.nextButtonText}}
            <i nz-icon nzType="arrow-right"></i>
          </p>
          <ng-template #buttonLoad>
            <div class="pd-d-flex ps-justify-content-center ps-align-items-center">
              <i nz-icon [nzType]="'loading'"></i>
            </div>
          </ng-template>
        </button>


      <div class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-between" *ngIf="step==4">
        <button class="book-slot-modal-detail-button sw-border-radius" nzType="default" nz-button (click)="prevStep()">
          <p purpleEditableText [tranKey]='this.steps.get(this.step)?.prevButtonTranslationKey' *ngIf="this.step!= undefined; else buttonLoad" class="ps-mb-0">
            <i nz-icon nzType="arrow-left"></i>
            {{this.steps.get(this.step)?.prevButtonText}}
          </p>
          <ng-template #buttonLoad>
            <div class="pd-d-flex ps-justify-content-center ps-align-items-center">
              <i nz-icon [nzType]="'loading'"></i>
            </div>
          </ng-template>
        </button>

        <button purpleEditableText tranKey='book_slot_info_close_button' (click)="closeModal()" class="book-slot-modal-detail-button sw-border-radius" nzType="primary" nz-button>
          <i nz-icon nzType="close-square" nzTheme="outline"></i>
          {{ 'book_slot_info_close_button' | translation: 'Chiudi': []}}
        </button>
       
      </div>
    </div>
  </div>

</div>
