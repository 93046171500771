<div class="user-profile-external-container ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center">
  <div class="user-profile-container ps-d-flex ps-flex-wrap ps-align-items-start">

    <div class="sale-events-preview-title-container">
      <h1 class="ps-w-100 ps-m-0 purple-title ps-d-none ps-d-md-block" purpleEditableText tranKey='user_profile_title'>{{
        'user_profile_title' | translation: 'Profilo': []}}</h1>
      <h2 class="ps-w-100 ps-m-0 purple-subtitle ps-d-none ps-d-md-block" purpleEditableText tranKey='user_profile_subtitle'>
        {{
        'user_profile_subtitle' | translation: 'I tuoi dati': []}}</h2>
      <h2 class="ps-w-100 ps-m-0 purple-title ps-d-block ps-d-md-none" purpleEditableText tranKey='user_profile_title_mobile'>
        {{
        'user_profile_title_mobile' | translation: 'Profilo': []}}</h2>
    </div>

    <!-- DESKTOP -->
    <div class="ps-w-100 ps-d-flex ps-flex-wrap user-profile-tabs-container"
      *ngIf="!isMobile && currentUser != undefined">
      <nz-tabset nzTabPosition="top" class="ps-w-100">
        <nz-tab [nzTitle]="'user_profile_tab_personal_info' | translation: 'Dati Personali': []">
          <div class="ps-w-100 ps-d-flex info-base-form-container">

            <form nz-form [formGroup]="validateForm"
              class="user-profile-base-info-form {{isFormEnable?'':'disable'}} ps-d-flex ps-flex-wrap"
              (ngSubmit)="submitForm()">

              <nz-form-item class="ps-col-6 ps-pl-0">
                <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
                  <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                    <p purpleEditableText tranKey='user_profile_base_info_firstname_title' class="form-item-title ps-w-100">{{ 'user_profile_base_info_firstname_title' | translation:
                      'Nome': []}}:</p>
                    <nz-input-group nzPrefixIcon="user">
                      <input [disabled]="!isFormEnable" purpleEditableText tranKey='user_profile_base_info_firstname'
                        type="text" nz-input formControlName="firstName" id="firstName"
                        [placeholder]="'user_profile_base_info_firstname' | translation: 'Nome': []" />
                    </nz-input-group>
                  </div>
                </nz-form-control>

              </nz-form-item>

              <nz-form-item class="ps-col-6 ps-pr-0">
                <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
                  <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                    <p purpleEditableText tranKey='user_profile_base_info_lastname_title' class="form-item-title ps-w-100">{{ 'user_profile_base_info_lastname_title' | translation:
                      'Cognome': []}}:</p>
                    <nz-input-group nzPrefixIcon="user">
                      <input [disabled]="!isFormEnable" purpleEditableText tranKey='user_profile_base_info_lastname'
                        type="text" nz-input formControlName="lastName" id="lastName"
                        [placeholder]="'user_profile_base_info_lastname' | translation: 'Cognome': []" />
                    </nz-input-group>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item *ngIf="!isKering" class="ps-col-6 ps-pl-0">
                <nz-form-control>
                  <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                    <p purpleEditableText tranKey='user_profile_base_info_email_title' class="form-item-title ps-w-100">{{ 'user_profile_base_info_email_title' | translation:
                      'Email': []}}:</p>
                    <nz-input-group nzPrefixIcon="mail">
                      <input [disabled]="true" purpleEditableText tranKey='user_profile_base_info_email'
                        type="text" nz-input formControlName="email"
                        [placeholder]="'user_profile_base_info_email' | translation: 'Email': []" />
                    </nz-input-group>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item *ngIf="!isKering" class="ps-col-6 ps-pr-0">
                <nz-form-control>
                  <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                    <p purpleEditableText tranKey='user_profile_base_info_birthdate_title' class="form-item-title ps-w-100">{{ 'user_profile_base_info_birthdate_title' | translation:
                      'Data di nascita': []}}:</p>
                    <nz-input-group nzPrefixIcon="calendar">

                      <nz-date-picker [nzDisabled]="!isFormEnable"
                        [nzPlaceHolder]="'user_profile_base_info_birthdate' | translation: 'Data di nascita utente': []"
                        nzFormat="dd-MM-yyyy" formControlName="birthdate" class="birthdate-select"></nz-date-picker>

                    </nz-input-group>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item *ngIf="!isKering" class="ps-col-6 ps-pl-0">
                <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
                  <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                    <p purpleEditableText tranKey='user_profile_base_info_phone_title' class="form-item-title ps-w-100">{{ 'user_profile_base_info_phone_title' | translation:
                      'Telefono': []}}:</p>
                    <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate" nzPrefixIcon="phone">
                      <ng-template #addOnBeforeTemplate>
                        <nz-select [nzDisabled]="!isFormEnable" nzShowSearch nzAllowClear
                          formControlName="phoneNumberPrefix" class="phone-select">
                          <nz-option *ngFor="let pref of phonePrefixes" [nzLabel]="pref" [nzValue]="pref"></nz-option>
                        </nz-select>
                      </ng-template>
                      <input [disabled]="!isFormEnable" purpleEditableText tranKey='user_profile_base_info_phone'
                        [placeholder]="'user_profile_base_info_phone' | translation: 'Telefono': []"
                        formControlName="phoneNumber" nz-input />
                    </nz-input-group>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item *ngIf="!isKering" class="ps-col-6 ps-pr-0">
                <nz-form-control 
                  [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
                  <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                    <p purpleEditableText tranKey='user_profile_base_info_gender_title' class="form-item-title ps-w-100">{{ 'user_profile_base_info_gender_title' | translation:
                      'Genere': []}}:</p>
                    <nz-input-group nzPrefixIcon="man">

                      <nz-select [nzDisabled]="!isFormEnable" purpleEditableText tranKey='edit_user_gender_placeholder_new'
                        class="gender-select"
                        [nzPlaceHolder]="'edit_user_gender_placeholder_new' | translation: 'Genere': []"
                        formControlName="gender">
                        <nz-option nzValue="M"
                          [nzLabel]="'user_profile_base_info_gender_man' | translation: 'Uomo': []">
                        </nz-option>
                        <nz-option nzValue="F"
                          [nzLabel]="'user_profile_base_info_gender_female' | translation: 'Donna': []">
                        </nz-option>
                        <nz-option nzValue="NS"
                          [nzLabel]="'user_profile_base_info_gender_not_specified' | translation: 'Non Specificato': []">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item *ngIf="!isKering" class="ps-col-12 ps-px-0">
                <nz-form-control>
                  <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                    <p purpleEditableText [tranKey]='this.regFullAdd ?"edit_profile_form_address_title_full":"edit_profile_form_address_title_city"' class="form-item-title ps-w-100">{{ fullAddressTitle}}:</p>
                    <nz-input-group nzPrefixIcon="home">
                      <purple-select [searchNameFunction]="addressSearchNameFunction" formControlName="address" cssClass="address-select"
                        ngDefaultControl [searchIdFunction]="addressSearchIdFunction" [currentItem]="currentExternalAddress"
                        [placeholder]="fullAddressPlaceholder" purpleEditableText [tranKey]='this.regFullAdd ?"register_form_address":"register_form_city"'
                        (onValueChange)="currentExternalAddress = $event" [disabled]="!isFormEnable" [refreshOptions]="refreshAddressSelect">
                      </purple-select>
                    </nz-input-group>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <div *ngIf="!isKering" class="ps-w-100 ps-d-flex ps-align-items-center">
                <button *ngIf="isFormEnable==false" (click)="enableForm()" purpleEditableText
                  tranKey='user_profile_base_info_button_edit' nz-button class="submit-button purple-button"
                  nzType="primary">{{ 'user_profile_base_info_button_edit' |
                  translation: 'Modifica Profilo': []}}</button>

                <ng-container *ngIf="isFormEnable">
                  <button (click)="cancelEdit()" purpleEditableText tranKey='user_profile_base_info_button_cancel' nz-button
                    class="submit-button purple-button" nzType="default">{{
                    'user_profile_base_info_button_cancel' |
                    translation: 'Cancella': []}}
                  </button>

                  <button [disabled]="isLoading" [nzLoading]="isLoading" (click)="submitForm()" purpleEditableText
                    tranKey='user_profile_base_info_button_save' nz-button class="save-button purple-button"
                    nzType="primary">{{
                    'user_profile_base_info_button_save' |
                    translation: 'Salva': []}}
                  </button>
                </ng-container>
              </div>
            </form>
          </div>
        </nz-tab>
        <nz-tab [nzTitle]="'user_profile_tab_preferences' | translation: 'Preferenze': []">
          <div class="ps-w-100 ps-d-flex user-attributes-container">
            <div *ngFor="let att of userAttributes"
              class="user-attributes-item-container ps-d-flex ps-flex-wrap ps-w-100">
              <h2 class="attribute-title ps-w-100">
                {{att.userAttributeName}}
              </h2>
              <container-element [ngSwitch]="att.angularTemplateType" class="ps-w-100">
                <ng-container *ngSwitchCase="'select-multiple'" class="ps-w-100">
                  <div class="choises-container ps-w-100 ps-d-flex ps-flex-wrap">
                    <div *ngFor="let defVal of att.userAttributeValues" class="ps-col-6 ps-d-flex">
                      <div class="choises-item ps-align-items-center ps-justify-content-between
                       ps-w-100 ps-d-flex ">
                        <p class="choise-text-value">
                          {{defVal.userAttributeValueText}}
                        </p>
                        <button nz-button class="choise-button" nzType="default"
                          (click)="removePreference(defVal.userAttributeValueId)">
                          <i nz-icon nzType="minus" nzTheme="outline"></i>
                          {{ 'add_user_attribute_prefernce_added' | translation: 'Rimuovi': []}}
                        </button>
                      </div>

                    </div>

                    <div *ngIf="att.canAddMore" class="ps-col-6 ps-d-flex">
                      <div class="choises-item ps-align-items-center ps-justify-content-between
                      ps-w-100 ps-d-flex ">
                        <p class="choise-text-value">
                          {{ 'add_new_user_attribute' | translation: 'Aggiungi nuovo interesse': []}}
                        </p>
                        <button nz-button class="choise-button" nzType="primary" (click)="addPreference()">
                          <i nz-icon nzType="plus" nzTheme="outline"></i>
                          {{ 'add_user_attribute_prefernce_add' | translation: 'Aggiungi': []}}
                        </button>
                      </div>
                    </div>

                  </div>
                </ng-container>

              </container-element>
            </div>
          </div>
        </nz-tab>
        <nz-tab [nzTitle]="'user_profile_tab_policies' | translation: 'Policy': []"
          *ngIf="saleEventPolicies.length > 0">
          <div class="ps-w-100 ps-d-flex preference-container">
            <div class="preference-box ps-d-flex ps-flex-wrap">
              <div class="sale-event-policy-box ps-w-100 ps-d-flex ps-flex-wrap"
                *ngFor="let sePolicy of saleEventPolicies">
                <div class="sale-event-name-container ps-w-100">
                  <h2 class="sale-event-name ps-w-100">
                    {{sePolicy.saleEventName | titlecase}}
                  </h2>
                </div>

                <div class="preference-item-container ps-col-6 ps-d-flex ps-flex-wrap"
                  *ngFor="let policy of sePolicy.policiesQueries">
                  <div class="preference-item ps-w-100 ps-d-flex ps-flex-wrap">
                    <h1 class="preference-title ps-mb-0 ps-w-100 purple-title">
                      {{policy.policyName}}
                    </h1>
                    <div class="ps-w-100 ps-d-flex ps-align-items-center">
                      <p class="ps-w-100 preference-description">
                        {{policy.policyDescription}}
                      </p>
                      <nz-switch class="prefernce-switch" [ngModel]="policyAccepted(policy)"
                        [nzDisabled]="policyBlocked(policy)"
                        (ngModelChange)="updateSaleEventUserPolicyEndorsement($event, policy)"></nz-switch>
                    </div>
                    <div class="ps-w-100 policy-link"><span purpleEditableText tranKey='show_ploicy'>{{ 'show_ploicy' | translation: 'Per visualizzare la policy':
                      []}}</span>&nbsp;

                      <container-element [ngSwitch]="policy.policyTypeId">

                        <ng-container *ngSwitchCase="'link'">
                          <a class="ps-href-reset" [href]="policy.policyUrl" target="_blank">{{ 'click_here' |
                            translation:
                            'clicca qui': []}}</a>
                        </ng-container>
                        <ng-container *ngSwitchCase="'iubenda'">
                          <a class="ps-href-reset" [href]="policy.policyUrl" target="_blank">{{ 'click_here' |
                            translation:
                            'clicca qui': []}}</a>
                        </ng-container>
                        <ng-container *ngSwitchCase="'media'">
                          <a class="ps-href-reset" [href]='policySvc.getPolicyImageUrl(policy)' target="_blank">{{
                            'click_here' |
                            translation:
                            'clicca qui': []}}</a>
                        </ng-container>
                        <ng-container *ngSwitchCase="'html'">
                          <a class="ps-href-reset" (click)="showPolicyPreview(policy)">{{ 'click_here' |
                            translation:
                            'clicca qui': []}}</a>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                          <a class="ps-href-reset" (click)="showPolicyPreview(policy)">{{ 'click_here' |
                            translation:
                            'clicca qui': []}}</a>
                        </ng-container>
                      </container-element>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-tab>
        <nz-tab [nzTitle]="'user_profile_tab_access_and_security' | translation: 'Accesso e Sicurezza': []">
          <div class="ps-w-100 ps-d-flex access-and-security-container">
            <div class="request-box ps-d-flex ps-flex-wrap">
              <h1 purpleEditableText tranKey='user_profile_reset_password_title'
                class="request-title ps-mb-0 ps-w-100 purple-title">{{
                'user_profile_reset_password_title' |
                translation: 'Reimposta la password'}}
              </h1>
              <p class="ps-w-100 request-description" purpleEditableText tranKey='user_profile_reset_password_description'>
                {{ 'user_profile_reset_password_description' | translation:
                'Ti invieremo un link per aggiornare la tua password. Se non hai mai creato una password ne puoi impostare una nuova.':[]}}
              </p>
              <div class="ps-w-100 ps-d-flex">
                <button purpleEditableText (click)="requestResetPassword()" [disabled]="isLoadingResetPass"
                  [nzLoading]="isLoadingResetPass" tranKey='user_profile_reset_password_button' nz-button
                  class="request-button purple-button" nzType="primary">{{ 'user_profile_reset_password_button' |
                  translation: 'Link per reimpostare la password': []}}</button>
              </div>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>

    <!-- MOBILE -->
    <div class="ps-w-100 ps-d-flex ps-flex-wrap user-profile-mobile-container" *ngIf="isMobile">
      <h2 class="ps-w-100 section-title" purpleEditableText tranKey='user_profile_base_info_title'>
        {{'user_profile_base_info_title' | translation: 'Informazioni base': []}}</h2>

      <div class="base-info-box ps-d-flex ">
        <form nz-form [formGroup]="validateForm"
          class="user-profile-base-info-form {{isFormEnable?'':'disable'}} ps-d-flex ps-flex-wrap"
          (ngSubmit)="submitForm()">

          <nz-form-item class="ps-col-12 ps-px-0">
            <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <p class="form-item-title ps-w-100">{{ 'user_profile_base_info_firstname_title' | translation:
                  'Nome': []}}:</p>
                <nz-input-group nzPrefixIcon="user">
                  <input [disabled]="!isFormEnable" purpleEditableText tranKey='user_profile_base_info_firstname' type="text"
                    nz-input formControlName="firstName" id="firstName"
                    [placeholder]="'user_profile_base_info_firstname' | translation: 'Nome': []" />
                </nz-input-group>
              </div>
            </nz-form-control>

          </nz-form-item>

          <nz-form-item class="ps-col-12 ps-px-0">
            <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <p class="form-item-title ps-w-100">{{ 'user_profile_base_info_lastname_title' | translation:
                  'Cognome': []}}:</p>
                <nz-input-group nzPrefixIcon="user">
                  <input [disabled]="!isFormEnable" purpleEditableText tranKey='user_profile_base_info_lastname' type="text"
                    nz-input formControlName="lastName" id="lastName"
                    [placeholder]="'user_profile_base_info_lastname' | translation: 'Cognome': []" />
                </nz-input-group>
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item *ngIf="!isKering" class="ps-col-12 ps-px-0">
            <nz-form-control>
              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <p class="form-item-title ps-w-100">{{ 'user_profile_base_info_birthdate_title' | translation:
                  'Data di nascita': []}}:</p>
                <nz-input-group nzPrefixIcon="calendar">

                  <nz-date-picker [nzDisabled]="!isFormEnable"
                    [nzPlaceHolder]="'user_profile_base_info_birthdate' | translation: 'Data di nascita utente': []"
                    nzFormat="dd-MM-yyyy" formControlName="birthdate" class="birthdate-select"></nz-date-picker>

                </nz-input-group>
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item *ngIf="!isKering" class="ps-col-12 ps-px-0">
            <nz-form-control>
              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <p class="form-item-title ps-w-100">{{ 'user_profile_base_info_email_title' | translation:
                  'Email': []}}:</p>
                <nz-input-group nzPrefixIcon="mail">
                  <input [disabled]="!isFormEnable" purpleEditableText tranKey='user_profile_base_info_email' type="text"
                    nz-input formControlName="email"
                    [placeholder]="'user_profile_base_info_email' | translation: 'Email': []" />
                </nz-input-group>
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item *ngIf="!isKering" class="ps-col-12 ps-px-0">
            <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <p class="form-item-title ps-w-100">{{ 'user_profile_base_info_phone_title' | translation:
                  'Telefono': []}}:</p>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate" nzPrefixIcon="phone">
                  <ng-template #addOnBeforeTemplate>
                    <nz-select [nzDisabled]="!isFormEnable" nzShowSearch nzAllowClear
                      formControlName="phoneNumberPrefix" class="phone-select">
                      <nz-option *ngFor="let pref of phonePrefixes" [nzLabel]="pref" [nzValue]="pref"></nz-option>
                    </nz-select>
                  </ng-template>
                  <input [disabled]="!isFormEnable" purpleEditableText tranKey='user_profile_base_info_phone'
                    [placeholder]="'user_profile_base_info_phone' | translation: 'Telefono': []"
                    formControlName="phoneNumber" id="'phoneNumber'" nz-input />
                </nz-input-group>
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item *ngIf="!isKering" class="ps-col-12 ps-px-0">
            <nz-form-control purpleEditableText tranKey='required_input_error'
              [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <p class="form-item-title ps-w-100">{{ 'user_profile_base_info_gender_title' | translation:
                  'Genere': []}}:</p>
                <nz-input-group nzPrefixIcon="man">

                  <nz-select [nzDisabled]="!isFormEnable" purpleEditableText tranKey='edit_user_gender_placeholder_new'
                    class="gender-select"
                    [nzPlaceHolder]="'edit_user_gender_placeholder_new' | translation: 'Genere': []"
                    formControlName="gender">
                    <nz-option nzValue="M" [nzLabel]="'user_profile_base_info_gender_man' | translation: 'Uomo': []">
                    </nz-option>
                    <nz-option nzValue="F"
                      [nzLabel]="'user_profile_base_info_gender_female' | translation: 'Donna': []">
                    </nz-option>
                    <nz-option nzValue="NS"
                      [nzLabel]="'user_profile_base_info_gender_not_specified' | translation: 'Non Specificato': []">
                    </nz-option>
                  </nz-select>
                </nz-input-group>
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item *ngIf="!isKering"  class="ps-col-12 ps-px-0">
            <nz-form-control>
              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <p class="form-item-title ps-w-100">{{fullAddressTitle}}:</p>
                <nz-input-group nzPrefixIcon="home">
                  <purple-select [searchNameFunction]="addressSearchNameFunction" formControlName="address" cssClass="address-select"
                        ngDefaultControl [searchIdFunction]="addressSearchIdFunction" [currentItem]="currentExternalAddress"
                        [placeholder]="fullAddressPlaceholder" purpleEditableText [tranKey]='this.regFullAdd ?"register_form_address":"register_form_city"'
                        (onValueChange)="currentExternalAddress = $event" [disabled]="!isFormEnable" [refreshOptions]="refreshAddressSelect">
                      </purple-select>
                </nz-input-group>
              </div>
            </nz-form-control>
          </nz-form-item>


          <div *ngIf="!isKering" class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-between">
            <button *ngIf="isFormEnable==false" (click)="enableForm()" purpleEditableText
              tranKey='user_profile_base_info_button_edit' nz-button class="submit-button purple-button"
              nzType="primary">{{ 'user_profile_base_info_button_edit' |
              translation: 'Modifica Profilo': []}}</button>

            <ng-container *ngIf="isFormEnable">
              <button (click)="cancelEdit()" purpleEditableText tranKey='user_profile_base_info_button_edit' nz-button
                class="cancel-button purple-button" nzType="default">{{
                'user_profile_base_info_button_cancel' |
                translation: 'Cancella': []}}
              </button>

              <button [disabled]="isLoading" [nzLoading]="isLoading" (click)="submitForm()" purpleEditableText
                tranKey='user_profile_base_info_button_edit' nz-button class="save-button purple-button"
                nzType="primary">{{
                'user_profile_base_info_button_save' |
                translation: 'Salva': []}}
              </button>
            </ng-container>
          </div>
        </form>
      </div>

      <div class="line"></div>
      <h2 class="ps-w-100 section-title" purpleEditableText tranKey='user_profile_contact_preference_title'>
        {{'user_profile_contact_preference_title' | translation: 'Preferenze': []}}</h2>
      <div class="ps-w-100 ps-d-flex preference-container">
        <div class="preference-box ps-d-flex ps-flex-wrap">

          <div class="ps-w-100 ps-d-flex user-attributes-container">
            <div *ngFor="let att of userAttributes" class="user-attributes-item-container ps-d-flex 
            ps-flex-wrap ps-w-100">
              <h2 class="attribute-title ps-w-100">
                {{att.userAttributeName}}
              </h2>
              <container-element [ngSwitch]="att.angularTemplateType" class="ps-w-100">
                <ng-container *ngSwitchCase="'select-multiple'">
                  <div class="choises-container ps-w-100 ps-d-flex ps-flex-wrap">
                    <div *ngFor="let defVal of att.userAttributeValues"
                      class="choises-item ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-between">
                      <p class="choise-text-value">
                        {{defVal.userAttributeValueText}}
                      </p>
                      <button nz-button class="choise-button" nzType="default"
                        (click)="removePreference(defVal.userAttributeValueId)">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                        {{ 'add_user_attribute_prefernce_added' | translation: 'Rimuovi': []}}
                      </button>
                    </div>

                    <div *ngIf="att.canAddMore" class="choises-item ps-align-items-center ps-justify-content-between
                      ps-w-100 ps-d-flex ">
                      <p class="choise-text-value">
                        {{ 'add_new_user_attribute' | translation: 'Aggiungi nuovo interesse': []}}
                      </p>
                      <button nz-button class="choise-button" nzType="primary" (click)="addPreference(true)">
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        {{ 'add_user_attribute_prefernce_add' | translation: 'Aggiungi': []}}
                      </button>
                    </div>
                  </div>
                </ng-container>
              </container-element>
            </div>
          </div>

        </div>
      </div>

      <div class="line"></div>
      <h2 class="ps-w-100 section-title" purpleEditableText tranKey='user_profile_access_and_security_title'>
        {{'user_profile_access_and_security_title' | translation: 'Accesso e Sicurezza': []}}</h2>

      <div class="ps-w-100 ps-d-flex access-and-security-container">
        <div class="request-box ps-d-flex ps-flex-wrap">
          <h1 purpleEditableText tranKey='user_profile_reset_password_title'
            class="request-title ps-mb-0 ps-w-100 purple-title">{{
            'user_profile_reset_password_title' |
            translation: 'Reimposta la password'}}
          </h1>
          <p class="ps-w-100 request-description" purpleEditableText tranKey='user_profile_reset_password_description'>
            {{ 'user_profile_reset_password_description' | translation:
            'Ti invieremo un link per aggiornare la tua password. Se non hai mai creato una password ne puoi impostare una nuova.': []}}
          </p>
          <div class="ps-w-100 ps-d-flex">
            <button purpleEditableText (click)="requestResetPassword()" [disabled]="isLoadingResetPass"
              [nzLoading]="isLoadingResetPass" tranKey='user_profile_reset_password_button' nz-button
              class="request-button purple-button" nzType="primary">{{ 'user_profile_reset_password_button' |
              translation: 'Link per reimpostare la password': []}}</button>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <h2 class="ps-w-100 section-title" purpleEditableText tranKey='user_profile_contact_policy_title'>
        {{'user_profile_contact_policy_title' | translation: 'Policy': []}}</h2>
      <div class="ps-w-100 ps-d-flex preference-container">
        <div class="preference-box ps-d-flex ps-flex-wrap">
          <div class="sale-event-policy-box" *ngFor="let sePolicy of saleEventPolicies">
            <div class="sale-event-name-container ps-w-100">
              <h2 class="sale-event-name ps-w-100">
                {{sePolicy.saleEventName | titlecase}}
              </h2>
            </div>
            <div class="preference-item ps-w-100 ps-d-flex ps-flex-wrap"
              *ngFor="let policy of sePolicy.policiesQueries">
              <div class="ps-w-100 ps-d-flex ps-align-items-center">
                <h1 class="preference-title ps-mb-0 ps-w-100 purple-title">
                  {{policy.policyName}}
                </h1>
                <nz-switch class="prefernce-switch" [ngModel]="policyAccepted(policy)"
                  [nzDisabled]="policyBlocked(policy)"
                  (ngModelChange)="updateSaleEventUserPolicyEndorsement($event, policy)"></nz-switch>
              </div>
              <p class="ps-w-100 preference-description">
                {{policy.policyDescription}}
              </p>
              <div class="ps-w-100 policy-link">{{ 'show_ploicy' | translation: 'Per visualizzare la policy':
                []}}&nbsp;

                <container-element [ngSwitch]="policy.policyTypeId">

                  <ng-container *ngSwitchCase="'link'">
                    <a class="ps-href-reset" [href]="policy.policyUrl" target="_blank">{{ 'click_here' |
                      translation:
                      'clicca qui': []}}</a>
                  </ng-container>
                  <ng-container *ngSwitchCase="'iubenda'">
                    <a class="ps-href-reset" [href]="policy.policyUrl" target="_blank">{{ 'click_here' |
                      translation:
                      'clicca qui': []}}</a>
                  </ng-container>
                  <ng-container *ngSwitchCase="'media'">
                    <a class="ps-href-reset" [href]='policySvc.getPolicyImageUrl(policy)' target="_blank">{{
                      'click_here' |
                      translation:
                      'clicca qui': []}}</a>
                  </ng-container>
                  <ng-container *ngSwitchCase="'html'">
                    <a class="ps-href-reset" (click)="showPolicyPreview(policy, true)">{{ 'click_here' |
                      translation:
                      'clicca qui': []}}</a>
                  </ng-container>
                  <ng-container *ngSwitchCase="'text'">
                    <a class="ps-href-reset" (click)="showPolicyPreview(policy, true)">{{ 'click_here' |
                      translation:
                      'clicca qui': []}}</a>
                  </ng-container>
                </container-element>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>