import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateContactsComponent } from './private-contacts/private-contacts.component';
import { PrivateMyReservationsComponent } from './private-my-reservations/private-my-reservations.component';
import { PrivateSaleEventDetailComponent } from './private-sale-event-detail/private-sale-event-detail.component';
import { PrivateSaleEventsPreviewComponent } from './private-sale-events-preview/private-sale-events-preview.component';
import { PrivateUserProfileComponent } from './private-user-profile/private-user-profile.component';


const routes: Routes = [
  { path: '', redirectTo: 'private-sales', pathMatch: 'full' },
  {
    path: 'contacts',
    component: PrivateContactsComponent,
    children:[
      {
        path: ':saleEventCode',
        component: PrivateSaleEventDetailComponent
      }
    ]
  },
  {
    path: 'private-sales',
    component: PrivateSaleEventsPreviewComponent
  },
  {
    path: 'detail',
    component: PrivateSaleEventDetailComponent,
    children:[
      {
        path: ':saleEventCode',
        component: PrivateSaleEventDetailComponent
      }
    ]
  },
  {
    path: 'my-reservations',
    component: PrivateMyReservationsComponent
  },
  {
    path: 'user-profile',
    component: PrivateUserProfileComponent
  }
];

@NgModule({

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }
