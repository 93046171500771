import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { PublicHeaderComponent } from './header/public-header/public-header.component';
import { PrivateHeaderComponent } from './header/private-header/private-header.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AuthenticationModule } from 'src/app/authentication/authentication.module';
import { FooterComponent } from './footer/footer.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ChangeLanguageDrawerComponent } from './drawers/change-language-drawer/change-language-drawer.component';
import { UserMenuDrawerComponent } from './drawers/user-menu-drawer/user-menu-drawer.component';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { PurpleEditImageModule, PurpleEditTextModule, PurpleGeneralDirectiveModule, PurplePipeModule } from 'purple-lib';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    PurplePipeModule,
    PurpleGeneralDirectiveModule,
    PurpleEditImageModule,
    PurpleEditTextModule,
    NzMenuModule,
    NzLayoutModule,
    NzSelectModule,
    NzMenuModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NzIconModule,
    NzDrawerModule,
    AuthenticationModule,
    NzButtonModule,
    NzPopoverModule,
    NzNotificationModule
  ],
  declarations: [PublicHeaderComponent, PrivateHeaderComponent, FooterComponent, ChangeLanguageDrawerComponent, UserMenuDrawerComponent],
  exports: [PublicHeaderComponent, PrivateHeaderComponent, FooterComponent]
})
export class LayoutModule { }
