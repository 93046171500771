import { Observable, Subject, throwError } from 'rxjs';
import { catchError, throttleTime } from 'rxjs/operators';

import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { AppTranslationService } from '../localization/localization.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private throttleLogout = new Subject<any>();

  constructor(private authenticationService: AuthenticationService, private router: Router, private tsvc: AppTranslationService, private modalSvc:NzModalService) {
    this.throttleLogout.pipe(throttleTime(5000)).subscribe(async url => {
      this.modalSvc.closeAll();
      this.authenticationService.logout();
      await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        console.log("Response: ", response)
        if (response.status === 0) {
          console.log("Connessione non disponibile")
          this.throttleLogout.next(1);
        }
        if (response.status === 401) {
          console.log("NON AUTORIZZATO")
          this.throttleLogout.next(1);
        }

        return throwError(() => new Error(response.error));
      }));
  }

}
