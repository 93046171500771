import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  isUserMenuOpen: boolean = false;

  constructor() { }
  showHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  showFooter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  fullContent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  qrCodeOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setHeaderVisibility(value: boolean) {
    this.showHeader.next(value);
  }

  setFooterVisibility(value: boolean) {
    this.showFooter.next(value);
  }

  setAllVisibility(value: boolean) {
    this.showHeader.next(value);
    this.showFooter.next(value);
  }

  setFullContent(value: boolean){
    this.fullContent.next(value);
  }
}
