import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PurpleStorageService, PurpleTranslationPipe } from 'purple-lib';
import { lastValueFrom } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, BooleanPurpleApiResponse, CheckReferral, PurpleApiResponseStatus } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  constructor(private bookSvc: BookingService, private storageSvc: PurpleStorageService, private tsvc: AppTranslationService, private mesSvc: NzMessageService, 
    private tranPipe: PurpleTranslationPipe, private notSvc: NzNotificationService) { }

  async reedemReferralCode() {
    const ref: CheckReferral | string = this.storageSvc.get<CheckReferral | string>('Referral', 'session');

    //console.log("Referral da storage: ", ref)


    if (ref != undefined && ref != null) {
      var res!: BooleanPurpleApiResponse;


      if (typeof ref == "string") {
        res = await lastValueFrom(this.bookSvc.redeemReferralCode(this.tsvc.currentLanguage.value, {
          referralId: ref
        }));
      } else {
        if (!ref.alreadyLoggedWithIncorrectDomain) {
          res = await lastValueFrom(this.bookSvc.redeemReferralCode(this.tsvc.currentLanguage.value, {
            referralId: ref.referralId
          }));

        }
      }

      if (res.status == PurpleApiResponseStatus.Success) {
        this.mesSvc.success(this.tranPipe.transform('message_redeem_referal_ok', 'Codice Invito utilizzato correttamente', []), {
          nzDuration: 5000
        });

        this.storageSvc.remove('Referral', 'session');
      } else if (res.status == PurpleApiResponseStatus.Warning) {
        this.mesSvc.warning(res.message, {
          nzDuration: 5000
        });

        this.storageSvc.remove('Referral', 'session');
      } else {
        this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), res.message, {nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top"});

        this.storageSvc.remove('Referral', 'session');
      }
    }
  }
}
