export * from './booking.service';
import { BookingService } from './booking.service';
export * from './clientSettings.service';
import { ClientSettingsService } from './clientSettings.service';
export * from './localization.service';
import { LocalizationService } from './localization.service';
export * from './medias.service';
import { MediasService } from './medias.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [BookingService, ClientSettingsService, LocalizationService, MediasService, UsersService];
