<div class="reset-password-external-container ps-w-100 ps-d-flex ps-flex-wrap">

  <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication" contentTypeId="authentication-image-mobile"
    purpleEditableImage>
      <div class="auth-form-mobile-banner-opacity-layer ps-w-100 ps-p-0 ps-h-100 ps-d-flex ps-justify-content-start ps-align-items-center">

          <div class="ps-col-6 ps-pl-4 ps-pr-0">
            <h1 class="auth-form-title ps-w-100" purpleEditableText tranKey='change_email_title'>{{ 'change_email_title' | translation: 'Cambia Indirizzo Email': []}}</h1>
          </div>

      </div>

    </div>

  <div
    class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
    <div class="ps-d-flex ps-w-100 ps-flex-wrap">
      <h1 class="auth-form-title ps-mb-0 ps-w-100 ps-d-lg-block ps-d-none"purpleEditableText tranKey='change_email_title'>{{ 'change_email_title' | translation: 'Cambia Indirizzo Email': []}}</h1>
      <h2 class="auth-form-subtitle purple-subtitle ps-w-100" purpleEditableText tranKey='change_email_subtitle'>{{ 'change_email_subtitle' | translation: 'Richiedi il cambio dell\'indirizzo email': []}}</h2>

      <div class="ps-col-12 ps-px-0 ps-d-flex ps-align-items-start">


        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">

          <nz-form-item>
            <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
              <nz-input-group purpleEditableText tranKey='change_email_email' nzPrefixIcon="mail">
                <input type="email" nz-input formControlName="email" [placeholder]="'change_email_email' | translation: 'Indirizzo Mail': []" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
              <nz-input-group nzPrefixIcon="mail">
                <input purpleEditableText tranKey='change_email_new_email_placeholder' type="email" nz-input formControlName="newEmail"
                  [placeholder]="'change_email_new_email_placeholder' | translation: 'Nuovo Indirizzo Mail': []" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzErrorTip]="error">
              <nz-input-group nzPrefixIcon="mail">
                <input purpleEditableText tranKey='change_email_new_email_confirm' [placeholder]="'change_email_new_email_confirm' | translation: 'Conferma Nuova Mail': []" nz-input
                  type="email" nz-input formControlName="confirmNewEmail" />
              </nz-input-group>

              <ng-template #error let-control>
                <ng-container purpleEditableText tranKey='required_input_error' *ngIf="control.hasError('required')">
                  {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
                </ng-container>
                <ng-container purpleEditableText tranKey='input_error_email_not_equal' *ngIf="control.hasError('confirm')">
                  {{ 'input_error_email_not_equal' | translation: 'I due indirizzi email non corrispondono': []}}
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <button nz-button class="submit-button ps-mt-4 sw-border-radius" nzType="primary" purpleEditableText tranKey='change_email_change_button'>{{ 'change_email_change_button' | translation: 'Richiedi cambio email': []}}</button>

        </form>
      </div>
    </div>
  </div>

  <div
    class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex ps-justify-content-center ps-align-items-end ps-justify-content-start">
    <div class="auth-form-vertical-image" contentId="{{seId}}-authentication" contentTypeId="authentication-image"
      purpleEditableImage>
    </div>
  </div>

</div>

