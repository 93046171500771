<div class="forgot-password-external-container ps-w-100 ps-d-flex ps-flex-wrap">

  <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication" contentTypeId="authentication-image-mobile"
    purpleEditableImage>
      <div class="auth-form-mobile-banner-opacity-layer ps-w-100 ps-p-0 ps-h-100 ps-d-flex ps-justify-content-start ps-align-items-center">

          <div class="ps-col-6 ps-pl-4 ps-pr-0">
            <h1 class="auth-form-title ps-w-100" purpleEditableText tranKey='forgot_password_title'>{{ 'forgot_password_title' | translation: 'Reset Password': []}}</h1>
          </div>

      </div>

    </div>

  <div
    class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
    <div class="ps-d-flex ps-w-100 ps-flex-wrap">
      <h1 class="auth-form-title ps-mb-0 ps-w-100 ps-d-lg-block ps-d-none" purpleEditableText tranKey='forgot_password_title'>{{ 'forgot_password_title' | translation: 'Reset Password': []}}</h1>
      <h2 class="auth-form-subtitle purple-subtitle ps-w-100" purpleEditableText tranKey='forgot_password_subtitle'>{{ 'forgot_password_subtitle' | translation: 'Richiedi il reset della password': []}}</h2>

      <div class="ps-col-12 ps-px-0 ps-d-flex ps-align-items-start">


        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">

          <nz-form-item>
            <nz-form-control purpleEditableText tranKey='forgot_passoword_form_email_required' [nzErrorTip]="'forgot_passoword_form_email_required' | translation: 'Inserisci il tuo indirizzo email': []">
              <nz-input-group nzPrefixIcon="mail">
                <input purpleEditableText tranKey='forgot_passoword_form_email' type="email" nz-input formControlName="email" [placeholder]="'forgot_passoword_form_email' | translation: 'Indirizzo email': []" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <button nz-button class="submit-button sw-border-radius ps-mt-4" nzType="primary" purpleEditableText tranKey='forgot_password_button'>{{ 'forgot_password_button' | translation: 'Richiedi reset password': []}}</button>

        </form>
      </div>
    </div>
  </div>

  <div
    class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex ps-justify-content-center ps-align-items-end ps-justify-content-start">
    <div class="auth-form-vertical-image" contentId="{{seId}}-authentication" contentTypeId="authentication-image"
      purpleEditableImage>
    </div>
  </div>

</div>

