import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription, lastValueFrom } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { ReferralService } from 'src/app/referral/referral.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PurpleApiResponseStatus, SaleEventPreview, UserNextSaleEventBookingItem } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { PrivateInviteFriendsDrawerComponent } from '../drawers/private-invite-friends-drawer/private-invite-friends-drawer.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PrivateInviteFriendsModalComponent } from '../modals/private-invite-friends-modal/private-invite-friends-modal.component';
import { InviteFriendsModalComponent } from '../modals/invite-friends-modal/invite-friends-modal.component';
import { InviteFriendsDrawerComponent } from '../drawers/invite-friends-drawer/invite-friends-drawer.component';
import { PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';

@Component({
  selector: 'app-private-sale-events-preview',
  templateUrl: './private-sale-events-preview.component.html',
  styleUrls: ['./private-sale-events-preview.component.scss']
})
export class PrivateSaleEventsPreviewComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute, private seSvc: SaleEventService, public tsvc: AppTranslationService, private loaderSvc: PurpleLoaderService, private breakpointObserver: BreakpointObserver,
    private bookSvc: BookingService, private drawerSvc: NzDrawerService, private tranPipe: PurpleTranslationPipe, private layoutSvc: LayoutService, private refSvc: ReferralService, private modalSvc:NzModalService) {
    this.showName = environment.SHOW_EVENTS_SALE_NAME;
  }


  saleEvents: SaleEventPreview[] = [];
  subtitleText: string = this.tranPipe.transform("sale_events_preview_subtitle", "Ecco a te le prossime private sales <span class='dot'>!</span>")
  options: AnimationOptions = {
    path: '/assets/loaders/' + environment.COMPANY_ABB + '/no-private-sales.json',
  };
  showName: boolean = true;
  isMobile: boolean = true;
  //carouselSlides: SaleEventCarouselSlideItem[] = [];
  subs: Subscription = new Subscription();

  async ngOnInit() {

    this.loaderSvc.addRequest("general");
    await this.refSvc.reedemReferralCode();
    this.layoutSvc.setFullContent(false);

    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 650px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    this.route.queryParams
      .subscribe(params => {
        this.seSvc.getSaleEventParameters(params)
      });

    const resp = await lastValueFrom(this.bookSvc.getCanBookSaleEvents(this.tsvc.currentLanguage.value));

    if (resp.status == PurpleApiResponseStatus.Success) {
      this.saleEvents = resp.data!
    } else {
      this.saleEvents = []
    }

    /* var res = await lastValueFrom(this.bookSvc.getHomepageCarouselSlides(this.tsvc.currentLanguage.value, {
      carouselId: "homepage_banner"
    }));

    if (res.status == PurpleApiResponseStatus.Success) {
      this.carouselSlides = res.data ?? [];
    } else {
      this.carouselSlides = [];
    } */


    this.loaderSvc.removeRequest("general");
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.layoutSvc.setFullContent(true);
  }

  InviteFriend(se: SaleEventPreview) {
    if (this.isMobile) {
      this.showInviteFriendsDrawer(se);
    } else {
      this.showInviteFriendsModal(se);
    }
  }


  showInviteFriendsModal(se: SaleEventPreview) {
    if (se.saleEvent.isPrivateInviteCounter) {
      this.modalSvc.create<PrivateInviteFriendsModalComponent, { saleEventId: string, saleEventName: string, saleEventPhone: string, saleEventEmail: string }>({
        nzContent: PrivateInviteFriendsModalComponent,
        nzTitle: undefined,
        nzWidth: '1000px',
        nzCloseIcon: undefined,
        nzClosable: false,
        nzCentered: true,
        nzClassName: "private-invite-friends-modal",
        nzData: {
          saleEventId: se.saleEvent.saleEventId,
          saleEventEmail: se.saleEvent.saleEventEmail,
          saleEventPhone: se.saleEvent.saleEventPhone,
          saleEventName: se.saleEvent.saleEventName
        },
        nzFooter: null,
        nzMaskClosable: false,
      });
    } else {
      if (se.isBooked) {
        this.modalSvc.create<InviteFriendsModalComponent, UserNextSaleEventBookingItem>({
          nzContent: InviteFriendsModalComponent,
          nzTitle: undefined,
          nzWidth: '1000px',
          nzCloseIcon: undefined,
          nzClosable: false,
          nzCentered: true,
          nzClassName: "invite-friends-modal",
          nzData: {
              address: se.saleEvent.addressFull!,
              addressExternalId: se.saleEvent.addressExternalId!,
              addressLat: se.saleEvent.addressLat!,
              addressLon: se.saleEvent.addressLon!,
              isPrivateInviteCounter: se.saleEvent.isPrivateInviteCounter,
              saleEventCode: se.saleEvent.saleEventCode,
              saleEventEmail: se.saleEvent.saleEventEmail,
              saleEventId: se.saleEvent.saleEventId,
              saleEventName: se.saleEvent.saleEventName,
              saleEventPhone: se.saleEvent.saleEventPhone,
              userCanInvite: se.canInvite,
              userCanDelete: true, //TODO
              slot: {
                bookingId: se.userSlotBooked!.saleEventSlotBookingId,
                requireAssistance: false,
                roleId: se.userSlotBooked!.roleId,
                roleName: se.userSlotBooked!.roleName,
                slotEndDate: se.userSlotBooked!.slotEndDate,
                slotStartDate: se.userSlotBooked!.slotStartDate,
                slotGmtInfo: se.userSlotBooked!.slotGmtInfo,
              }
          },
          nzFooter: null,
          nzMaskClosable: false,
        });
      }
    }
  }

  checkCanInvite(se: SaleEventPreview): boolean{
    if(se.saleEvent.isPrivateInviteCounter){
      return se.canInvite;
    }else{
      return se.isBooked && se.canInvite;
    }
  }

  showInviteFriendsDrawer(se: SaleEventPreview) {
    if (se.saleEvent.isPrivateInviteCounter) {
      this.drawerSvc.create<PrivateInviteFriendsDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: PrivateInviteFriendsDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "private-invite-friends",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          saleEventId: se.saleEvent.saleEventId
        }
      });
    } else {
      if (se.isBooked) {
        this.drawerSvc.create<InviteFriendsDrawerComponent, { value: string }, string>({
          nzTitle: undefined,
          nzContent: InviteFriendsDrawerComponent,
          nzClosable: false,
          nzWrapClassName: "invite-friends",
          nzFooter: undefined,
          nzPlacement: 'bottom',
          nzContentParams: {
            book: {
              address: se.saleEvent.addressFull!,
              addressExternalId: se.saleEvent.addressExternalId!,
              addressLat: se.saleEvent.addressLat!,
              addressLon: se.saleEvent.addressLon!,
              isPrivateInviteCounter: se.saleEvent.isPrivateInviteCounter,
              saleEventCode: se.saleEvent.saleEventCode,
              saleEventEmail: se.saleEvent.saleEventEmail,
              saleEventId: se.saleEvent.saleEventId,
              saleEventName: se.saleEvent.saleEventName,
              saleEventPhone: se.saleEvent.saleEventPhone,
              userCanInvite: se.canInvite,
              userCanDelete: true, //TODO
              slot: {
                bookingId: se.userSlotBooked!.saleEventSlotBookingId,
                requireAssistance: false,
                roleId: se.userSlotBooked!.roleId,
                roleName: se.userSlotBooked!.roleName,
                slotEndDate: se.userSlotBooked!.slotEndDate,
                slotStartDate: se.userSlotBooked!.slotStartDate,
                slotGmtInfo: se.userSlotBooked!.slotGmtInfo,
              }
            }
          }
        }).afterClose.subscribe(() => {
          this.layoutSvc.qrCodeOpen.next(false)
        })
      }
    }
  }


}
