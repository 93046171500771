/**
 * Api v3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ApiUploadCompeteMediaPurpleApiResponse } from '../model/models';
import { BooleanPurpleApiResponse } from '../model/models';
import { CheckIfContentMediaExist } from '../model/models';
import { CheckIfContentMediaExistUploadRequest } from '../model/models';
import { CheckIfContentMediaExistUploadResponsePurpleApiResponse } from '../model/models';
import { CheckIfMediaExistUploadRequest } from '../model/models';
import { CheckIfMediaExistUploadResponsePurpleApiResponse } from '../model/models';
import { DeleteContentMediaRequest } from '../model/models';
import { GetAllImagesRequest } from '../model/models';
import { GetAllImagesResponsePurpleApiResponse } from '../model/models';
import { PurpleApiResponse } from '../model/models';
import { UpdateMediaIdRequest } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MediasService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param culture 
     * @param checkIfContentMediaExist 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfContentMediaExist(culture: string, checkIfContentMediaExist?: CheckIfContentMediaExist, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public checkIfContentMediaExist(culture: string, checkIfContentMediaExist?: CheckIfContentMediaExist, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public checkIfContentMediaExist(culture: string, checkIfContentMediaExist?: CheckIfContentMediaExist, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public checkIfContentMediaExist(culture: string, checkIfContentMediaExist?: CheckIfContentMediaExist, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CheckIfContentMediaExist.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/Medias/CheckIfContentMediaExist`,
            checkIfContentMediaExist,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param checkIfContentMediaExistUploadRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfContentMediaExistUpload(culture: string, checkIfContentMediaExistUploadRequest?: CheckIfContentMediaExistUploadRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CheckIfContentMediaExistUploadResponsePurpleApiResponse>;
    public checkIfContentMediaExistUpload(culture: string, checkIfContentMediaExistUploadRequest?: CheckIfContentMediaExistUploadRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CheckIfContentMediaExistUploadResponsePurpleApiResponse>>;
    public checkIfContentMediaExistUpload(culture: string, checkIfContentMediaExistUploadRequest?: CheckIfContentMediaExistUploadRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CheckIfContentMediaExistUploadResponsePurpleApiResponse>>;
    public checkIfContentMediaExistUpload(culture: string, checkIfContentMediaExistUploadRequest?: CheckIfContentMediaExistUploadRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CheckIfContentMediaExistUpload.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CheckIfContentMediaExistUploadResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/Medias/CheckIfContentMediaExistUpload`,
            checkIfContentMediaExistUploadRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param checkIfMediaExistUploadRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CheckIfMediaExistUploadResponsePurpleApiResponse>;
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CheckIfMediaExistUploadResponsePurpleApiResponse>>;
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CheckIfMediaExistUploadResponsePurpleApiResponse>>;
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CheckIfMediaExistUpload.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CheckIfMediaExistUploadResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/Medias/CheckIfMediaExistUpload`,
            checkIfMediaExistUploadRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteContentMediaRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteContentMedia(culture: string, deleteContentMediaRequest?: DeleteContentMediaRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteContentMedia(culture: string, deleteContentMediaRequest?: DeleteContentMediaRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteContentMedia(culture: string, deleteContentMediaRequest?: DeleteContentMediaRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteContentMedia(culture: string, deleteContentMediaRequest?: DeleteContentMediaRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteContentMedia.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/Medias/DeleteContentMedia`,
            deleteContentMediaRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getAllImagesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllIdsImages(culture: string, getAllImagesRequest?: GetAllImagesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetAllImagesResponsePurpleApiResponse>;
    public getAllIdsImages(culture: string, getAllImagesRequest?: GetAllImagesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetAllImagesResponsePurpleApiResponse>>;
    public getAllIdsImages(culture: string, getAllImagesRequest?: GetAllImagesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetAllImagesResponsePurpleApiResponse>>;
    public getAllIdsImages(culture: string, getAllImagesRequest?: GetAllImagesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetAllIdsImages.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetAllImagesResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/Medias/GetAllIdsImages`,
            getAllImagesRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param updateMediaIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMediaId(culture: string, updateMediaIdRequest?: UpdateMediaIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleApiResponse>;
    public updateMediaId(culture: string, updateMediaIdRequest?: UpdateMediaIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleApiResponse>>;
    public updateMediaId(culture: string, updateMediaIdRequest?: UpdateMediaIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleApiResponse>>;
    public updateMediaId(culture: string, updateMediaIdRequest?: UpdateMediaIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UpdateMediaId.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/Medias/UpdateMediaId`,
            updateMediaIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param mediaFile 
     * @param contentTypeId 
     * @param contentId 
     * @param contentMediaIndex 
     * @param name 
     * @param description 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadComplete(culture: string, mediaFile: Blob, contentTypeId: string, contentId: string, contentMediaIndex: number, name?: string, description?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ApiUploadCompeteMediaPurpleApiResponse>;
    public uploadComplete(culture: string, mediaFile: Blob, contentTypeId: string, contentId: string, contentMediaIndex: number, name?: string, description?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ApiUploadCompeteMediaPurpleApiResponse>>;
    public uploadComplete(culture: string, mediaFile: Blob, contentTypeId: string, contentId: string, contentMediaIndex: number, name?: string, description?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ApiUploadCompeteMediaPurpleApiResponse>>;
    public uploadComplete(culture: string, mediaFile: Blob, contentTypeId: string, contentId: string, contentMediaIndex: number, name?: string, description?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UploadComplete.');
        }
        if (mediaFile === null || mediaFile === undefined) {
            throw new Error('Required parameter mediaFile was null or undefined when calling UploadComplete.');
        }
        if (contentTypeId === null || contentTypeId === undefined) {
            throw new Error('Required parameter contentTypeId was null or undefined when calling UploadComplete.');
        }
        if (contentId === null || contentId === undefined) {
            throw new Error('Required parameter contentId was null or undefined when calling UploadComplete.');
        }
        if (contentMediaIndex === null || contentMediaIndex === undefined) {
            throw new Error('Required parameter contentMediaIndex was null or undefined when calling UploadComplete.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (mediaFile !== undefined) {
            formParams = formParams.append('MediaFile', <any>mediaFile) as any || formParams;
        }
        if (contentTypeId !== undefined) {
            formParams = formParams.append('ContentTypeId', <any>contentTypeId) as any || formParams;
        }
        if (contentId !== undefined) {
            formParams = formParams.append('ContentId', <any>contentId) as any || formParams;
        }
        if (contentMediaIndex !== undefined) {
            formParams = formParams.append('ContentMediaIndex', <any>contentMediaIndex) as any || formParams;
        }
        if (name !== undefined) {
            formParams = formParams.append('Name', <any>name) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('Description', <any>description) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ApiUploadCompeteMediaPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/Medias/UploadComplete`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
