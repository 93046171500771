import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService, registerResponse } from 'src/core-modules/authentication/authentication.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PolicyItem, PRPVSaleEventFull, FeedbackPolicy } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferralService } from 'src/app/referral/referral.service';
import { Subscription } from 'rxjs';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Guid, Helper, PurpleEditService, PurpleSelectFilter, PurpleTranslationPipe } from 'purple-lib';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  validateForm!: UntypedFormGroup;
  companyName = "";
  seId: string = "";
  fullAddressPlaceholder: string = "";
  policies: PolicyItem[] = [];
  isMobile: boolean = false;
  registerBookingPolicies: FeedbackPolicy[] = [];
  titleText: string = "";
  subSubtitleText: string = "";
  subs: Subscription = new Subscription();

  constructor(private fb: UntypedFormBuilder, private authenticationService: AuthenticationService, private bookSvc: BookingService, private router: Router, private editSvc: PurpleEditService, private breakpointObserver: BreakpointObserver,
    public tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private tranPipe: PurpleTranslationPipe, private refSvc: ReferralService, public policySvc: PolicyAndUserAttributesService) {
    this.companyName = environment.COMPANY_NAME ?? "PurpleBooking";
    this.phonePrefixes = Helper.getPhonePrefixes();

    this.fullAddressPlaceholder = this.regFullAdd ? this.tranPipe.transform('register_form_address', 'Indirizzo (Opzionale)', []) : this.tranPipe.transform('register_form_city', 'Città (Opzionale)', [])
    this.titleText = this.tranPipe.transform('register_title_new', 'Benvenuto in {0}!', [this.companyName])
    this.subSubtitleText = this.tranPipe.transform('register_if_not_saywow_account', 'Registrati se non hai un account saywow', [])
  }

  subtitleText: string = this.tranPipe.transform("register_subtitle", "Registrati o accedi<span class='dot'>.</span>")
  //informationText: string = "";

  addressId: string | undefined;
  phonePrefixes: string[] = [];
  useIubenda = environment.USE_IUBENDA;
  regFullAdd = environment.REGISTRATION_FULL_ADDRESS;

  async submitForm(): Promise<void> {
    //console.log("SubmitForm")

    this.validateForm.controls["address"].setValue(this.addressId);
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    //console.log("SubmitForm: ", this.validateForm.valid)
    if (this.validateForm.valid) {

      this.policies.forEach(policy => {
        this.registerBookingPolicies.push({
          policyId: policy.policyId,
          policyCheck: this.validateForm.controls[policy.policyId].value
        })
      });

      await this.authenticationService.register(
        (this.validateForm.controls["firstName"].value as string).trim(),
        (this.validateForm.controls["lastName"].value as string).trim(),
        (this.validateForm.controls["email"].value as string).trim(),
        this.validateForm.controls["gender"].value,
        (this.validateForm.controls["password"].value as string).trim(),
        this.validateForm.controls["phoneNumber"].value??undefined,
        this.validateForm.controls["phoneNumberPrefix"].value??undefined,
        this.validateForm.controls["address"].value,
        this.registerBookingPolicies,
        this.seSvc.returnUrl,
        this.seSvc.currentSaleEvent.value?.saleEventId ?? undefined,
        window.location.hostname
      ).then(
        async (registerRes: registerResponse) => {
          if (registerRes.registerOk) {

            //await this.refSvc.reedemReferralCode();
            /* if (registerRes.returnUrl != undefined) {
              await this.router.navigate([this.tsvc.currentLanguage.value, 'login'], { queryParams: { returnUrl: registerRes.returnUrl } });
            } else {
              await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
            } */

            this.authenticationService.login((this.validateForm.controls["email"].value as string).trim(), (this.validateForm.controls["password"].value as string).trim()).then(
              async (authenticated: boolean) => {
                if (authenticated) {
                  //console.log('Autenticato');
                  await this.refSvc.reedemReferralCode();

                  if (this.seSvc.returnUrl != undefined) {
                    await this.router.navigateByUrl(this.seSvc.returnUrl);
                  } else {
                    await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
                  }
                }
              }
            )
          }
        }
      )
    }
  }

  //#region Address

  //SearchFunction
  /* addressSearchFunction=(args: { pageNumber: number, pageSize: number, culture: string, searchName: string }) =>{
    return this.bookSvc.getAddressByNameSelect(args.culture, args)
  }

  addressSearchIdFunction=(args: { pageNumber: number, pageSize: number, culture: string, searchName: string }) =>{
    return this.bookSvc.getAddressByIdSelect(args.culture, args)
  } */

  addressSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByNameSelect(args.culture, args)
    }
  }

  addressSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByIdSelect(args.culture, args)
    }
  }
  //#endregion

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  validatePassword = (control: UntypedFormControl): { [s: string]: boolean } => {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[,;\.!@#\$%\^&\*])(?=.{8,})");
    const res = re.test(String(control.value));
    //console.log("test pass: ", res)
    if (!res) {
      return { format: true, error: true }
    }
    return {}
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 650px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined) => {
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

    this.validateForm = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      gender: [null, [Validators.required]],
      address: [null, [Validators.required]],
      password: [null, [Validators.required, this.validatePassword]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      phoneNumberPrefix: [this.seSvc.currentSaleEvent.value?.saleEventPhonePrefix ?? environment.DEFAULT_PHONE_PREFIX],
      phoneNumber: [null, [Validators.required]]
    });

    this.subs.add(this.policySvc.publicPolicies.subscribe(s => {
      this.policies = (s.filter(f => f.pageName?.strEq('register')))[0]?.policies ?? [];

      this.policies.forEach(policy => {
        this.validateForm.addControl(policy.policyId, new UntypedFormControl(false, policy.isMandatoryPolicy ? Validators.requiredTrue : null));
      });
    }));

    if (this.authenticationService.isLoggedIn && !(this.editSvc.editModeOn.value && this.authenticationService.isAdminUser())) {
      await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
    }

    this.route.queryParams
      .subscribe(params => {
        this.seSvc.getSaleEventParameters(params)
      });
  }
}
